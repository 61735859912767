import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DateFormat } from 'src/app/constants/constants';
import { ExperienceAndEducation } from 'src/app/core/interfaces/ExperienceAndEducation';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-experience-education-view',
  templateUrl: './experience-education-view.component.html',
  styleUrls: ['./experience-education-view.component.less']
})
export class ExperienceEducationViewComponent {
  closeCurrentTab() {
    if (confirm("Close the current tab?")) {
      close();
    }
  }
  @Output() isLoader = new EventEmitter<boolean>;
  lawGroupData: any = []
  barAssociationData:any=[]
  countryData:any=[]
  degreeData:any=[]
  universityData:any=[]
  personId: number=0 ;
  format:any=DateFormat.yyyyddmm;
  experienceAndEducationList: ExperienceAndEducation = ({ barAssocaiationList: [], createdBy: "", educationList: [], experienceList: [], membershipList: [], modifiedBy: "", personId: 0 })

  constructor(private apiService: LFDAPIService, private dataservice: DataService,private session: SessionStorageService,private router:Router,private routes:ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.getUserDetails();
    }
    }
    else{
      this.getUserDetails();
    }
  }
  getUserDetails(){

    let loggedInUserData=this.session.getItem('userdetail');
    this.personId=loggedInUserData?.personId;
     //check query param and update id
     let paramPersonID=this.routes.snapshot.queryParams['personId'];
     if(paramPersonID){
      let paramPId=parseInt(paramPersonID);
       this.personId=paramPId;
       
     }
     this.getExperienceAndEducationViewTabData();
  }
  getExperienceAndEducationViewTabData() {
    this.isLoader.emit(true);
    this.apiService.getExperienceAndEducationTabData(this.personId).subscribe({
      next: (res) => {
        this.experienceAndEducationList.experienceList = [...res.body.experienceList];
        this.experienceAndEducationList.membershipList = [...res.body.membershipList];
        this.experienceAndEducationList.barAssocaiationList = [...res.body.barAssocaiationList];
        this.experienceAndEducationList.educationList = [...res.body.educationList];
        
      },
      error: (err) => {
        console.log("Unable to retrieve Case Project Publication TabData", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }
  directoryPage(){
    this.router.navigate(
      ['/directory']
    );
  }
}
