import { ProjectCase, Publication, CaseProjectsNPublications } from 'src/app/core/interfaces/CaseProjectsAndPublications';
import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DateFormat, Role, SUCCESS } from 'src/app/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-case-projects',
  templateUrl: './case-projects.component.html',
  styleUrls: ['./case-projects.component.less']

})
 
export class CaseProjectsComponent implements OnInit {
  isVisibleSuccess=false
  lblSuccessPopupMessage='';
  isRequiredError=false;
  requiredErrorText="";
  format:any=DateFormat.yyyyddmm;
  @Output() isLoader = new EventEmitter<boolean>;
  constructor(private apiService: LFDAPIService, private session: SessionStorageService,private dataservice: DataService, private fb: FormBuilder,private router:Router,private routes:ActivatedRoute) {
  }

  cancelPublicationPopup() {
    this.isPublicationVisible = false;
    this.isRequiredError=false;
  }
  RequiredErrorTitle(){
    if(this.selectedPublication.title){
      this.isRequiredError=false;
      }
  }

  RequiredErrorProjectInitiative(){
    if(this.selectedProject.name){
      this.isRequiredError=false;
      }
  }
  cancelProjectPopup() {
    this.isProjectVisible = false;
    this.isRequiredError=false;
  }
  saveProject() {
      
      if (!this.isUpdateRecord) {
        if(this.selectedProject.name){
          //check dublicate name
          if(this.caseProjectsPublications.projectsList.filter(x=>x.name==this.selectedProject.name).length <= 0){
            this.caseProjectsPublications.projectsList.push({ description: this.selectedProject.description==undefined?null:this.selectedProject.description, name: this.selectedProject.name, id: 0, isActive: true })
            this.isUpdateRecord = false;
            this.isProjectVisible = false;
          }
          else{
           this.isRequiredError=true;
           this.requiredErrorText="Project name already exists!";
          }
         }
         else{
           //
           this.isRequiredError=true;
           this.requiredErrorText="Project name is required!";
         }


        
      }
      else{
        this.isProjectVisible = false;
      }
    
  }
  savePublication() {
    if (!this.isUpdateRecord) {
      if(this.selectedPublication.title){
        //check dublicate name
        if(this.caseProjectsPublications.publicationsList.filter(x=>x.title==this.selectedPublication.title).length <= 0){
          this.caseProjectsPublications.publicationsList.push({ description: this.selectedPublication.description==undefined?null:this.selectedPublication.description, title: this.selectedPublication.title, id: 0, institution: this.selectedPublication.institution==undefined?null:this.selectedPublication.institution, publishedDate: this.selectedPublication.publishedDate==undefined?null: this.selectedPublication.publishedDate, link: this.selectedPublication.link==undefined?null:this.selectedPublication.link, isActive: true })
          this.isUpdateRecord = false;
          this.isPublicationVisible = false;
        }
        else{
         this.isRequiredError=true;
         this.requiredErrorText="Title already exists!";
        }
       }
       else{
         //
         this.isRequiredError=true;
         this.requiredErrorText="Title is required!";
       }
    }
    else{
      this.isPublicationVisible = false;
    }
  }
  selectedMajorCase: any;
  isMajorCaseVisible = false;
  selectedProject: any;
  isProjectVisible = false;
  selectedPublication: any;
  isPublicationVisible = false;
  isUpdateRecord = false;
  personId: number=0 ;
  userDetails :  any;
  getUserDetails(){
   
    let loggedInUserData=this.session.getItem('userdetail');
    this.personId=loggedInUserData.personId;
     //check query param and update id
     let paramPersonID=this.routes.snapshot.queryParams['personId'];
     if(paramPersonID){
      let paramPId=parseInt(paramPersonID);
      this.personId=paramPId;
     }
     this.getCaseProjectPublicationTabData();
    }

  addMajorCase() {
    this.isMajorCaseVisible = true;
    this.selectedMajorCase = {} as ProjectCase;
    this.isUpdateRecord = false;
  }

  saveMajorCase() {
    if (!this.isUpdateRecord) {
       if(this.selectedMajorCase.name){
        //check dublicate name
        if(this.caseProjectsPublications.majorCasesList.filter(x=>x.name==this.selectedMajorCase.name).length <= 0){
          this.caseProjectsPublications.majorCasesList.push({ description: this.selectedMajorCase.description==undefined?null:this.selectedMajorCase.description, name: this.selectedMajorCase.name, id: 0, isActive: true })
          this.isUpdateRecord = false;
          this.isMajorCaseVisible = false;
        }
        else{
         this.isRequiredError=true;
         this.requiredErrorText="Case/Transaction name already exists!";
        }
       }
       else{
         //
         this.isRequiredError=true;
         this.requiredErrorText="Case/Transaction name is required!";
       }
    }
    else{
      this.isMajorCaseVisible = false;
    }

  }
  editMajorCase(data: ProjectCase) {
    this.isMajorCaseVisible = true;
    this.selectedMajorCase = data;
    this.isUpdateRecord = true;
  }

  async ngOnInit(): Promise<void> {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.getUserDetails();
    }
    }
    else{
      this.getUserDetails();
    }
    
  }
  cancelMajorCasePopup() {
    this.isMajorCaseVisible = false;
    this.isRequiredError=false;
  }
  RequiredErrorTransationName(){
    if(this.selectedMajorCase.name){
      this.isRequiredError=false;
      }
  }

  deleteMajorCase(data: ProjectCase) {
    data.isActive = false;
    this.selectedMajorCase = data;
  }

  deleteProject(data: ProjectCase) {
    data.isActive = false;
    this.selectedProject = data;
  }
  editProject(data: ProjectCase) {
    this.isProjectVisible = true;
    this.selectedProject = data;
    this.isUpdateRecord = true;
  }
  addProject() {
    this.isProjectVisible = true;
    this.selectedProject = {} as ProjectCase;
    this.isUpdateRecord = false;
  }
  editPublications(data: Publication) {
    this.isPublicationVisible = true;
    this.selectedPublication = data;
    this.isUpdateRecord = true;
  }
  deletePublications(data: Publication) {
    data.isActive = false;
    this.selectedPublication = data;
  }
  addPublications() {
    this.isPublicationVisible = true;
    this.selectedPublication = {} as Publication;
    this.isUpdateRecord = false;
  }
  caseProjectsPublications: CaseProjectsNPublications = (
    {
      personId: this.personId, majorCasesList: [], projectsList: [], publicationsList: [], createdBy: "", modifiedBy: ""
    }
  );

  getCaseProjectPublicationTabData() {
    this.isLoader.emit(true);
    this.apiService.getCaseProjectPublicationTabData(this.personId).subscribe({
      next: (res) => {
        this.caseProjectsPublications.majorCasesList = [...res.body.majorCasesList];
        this.caseProjectsPublications.projectsList = [...res.body.projectsList];
        this.caseProjectsPublications.publicationsList = [...res.body.publicationsList];
        this.caseProjectsPublications.personId = res.body.personId;
      },
      error: (err) => {
        console.log("Unable to retrieve Case Project Publication TabData", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  saveCaseProjectPublication(): void {
    this.caseProjectsPublications.personId = this.personId;
    this.apiService.saveCaseProjectPublication(this.caseProjectsPublications).subscribe(output => {
      if (output.body == SUCCESS ) {
        this.isVisibleSuccess=true;
        this.lblSuccessPopupMessage="Record Saved Successfully";
        this.ngOnInit();
      } else {
        //console.log("Unable to save proposal overview");
        this.isVisibleSuccess=true;
        this.lblSuccessPopupMessage="Something went wrong ,please try again.";
      }

      this.isLoader.emit(false);
    })
  }
  
  CancelOKSuccessPopup(){
    this.isVisibleSuccess=false;
    this.lblSuccessPopupMessage='';
  }
  directoryPage(){
    this.router.navigate(
      ['/directory']
    );
  }
  
}
