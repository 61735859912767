<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">major cases & transactions</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Case/Transaction Name</th>
                    <th>Work Description</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.majorCasesList">
                    <td *ngIf="data.isActive === true">{{data.name}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>

                </tr>
            </tbody>
        </nz-table>

    </div>

</div>


<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">project & initiatives</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Project</th>
                    <th>Work Description</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.projectsList">
                    <td *ngIf="data.isActive === true">{{data.name}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>

                </tr>
            </tbody>
        </nz-table>

    </div>

</div>


<pre></pre>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">publications</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Institution</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.publicationsList">
                    <td *ngIf="data.isActive === true"><span *ngIf="data?.link?.length === null || data?.link?.length === 0">{{data.title}}</span> <a *ngIf="(data?.link?.length || 0 < 0) && data?.link?.indexOf('http')!==-1" target="_blank" href="{{data.link}}">{{data.title}}</a> <a *ngIf="(data?.link?.length || 0 < 0) && data?.link?.indexOf('http')==-1" target="_blank" href="{{'//'+data.link}}">{{data.title}}</a>  </td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.publishedDate | date: format }}</td>
                </tr>
            </tbody>
        </nz-table>

    </div>

</div>
<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>