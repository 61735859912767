

import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { CalAngularService } from '@cvx/cal-angular';
import { UserDetails } from 'src/app/core/interfaces/User';
import { Menu, Role } from 'src/app/constants/constants';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { error } from 'jquery';

@Component({
  selector: 'app-view-profiles',
  templateUrl: './view-profiles.component.html',
  styleUrls: ['./view-profiles.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class ViewProfilesComponent implements OnInit {
  activeCasesTab() {
    this.isCaseTabActive = true;
    this.isExpTabActive = false;
    this.isSkillTabActive = false;
  }
  activeExperienceTab() {
    this.isCaseTabActive = false;
    this.isExpTabActive = true;
    this.isSkillTabActive = false;
  }
  activeSkillTab() {
    this.isCaseTabActive = false;
    this.isExpTabActive = false;
    this.isSkillTabActive = true;
  }
  user: UserDetails[] = [];
  userphoto1: SafeResourceUrl = "";
  personId: number = 0;
  selectedUserMail: any;
  isCaseTabActive = false;
  isExpTabActive = false;
  isSkillTabActive = true;
  userDetails: any;

  getUserDetailsByPersonId(personId: any) {
    this.apiService.getUserDetailByPersonId(personId).subscribe(result => {
      if (result.status === 200 && result.body) {
        this.userDetails = result.body;
      }

    });
  }
  getAndSetPhoto() {
    this.msgraph.getUserPhotoByEmail(this.selectedUserMail).subscribe({

      next: data => {
        let jsonKey: string = "";
        new Uint8Array(data).forEach(function (byte: number) { jsonKey += String.fromCharCode(byte) })
        let base64String = btoa(jsonKey);
        localStorage.setItem('photo', base64String);
        
        this.session.setItem("ss_user_photo1", this.sanitizer.bypassSecurityTrustUrl(
          'data:image/*;base64, ' + base64String
        ));
        this.userphoto1 = this.session.getItem("ss_user_photo1").changingThisBreaksApplicationSecurity;
      }, 
      error: (err) => {
        this.userphoto1 = "assets/Images/User.png";
      }
    });
  }
  loaduserDetails(){
    this.dataservice.higlightCurrentMenu("");
    
    let paramPersonID = this.routes.snapshot.queryParams['personId'];
    if (paramPersonID) {
      let paramPId = parseInt(paramPersonID);
      
      this.personId = paramPId;
      this.selectedUserMail = this.routes.snapshot.queryParams['email'];
      this.getAndSetPhoto();
    }
    this.getUserDetailsByPersonId(this.personId);
  }
  async ngOnInit(): Promise<void> {
    //this.dataservice.getAccessToken();
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.loaduserDetails();
    }
    }
    else{
      this.loaduserDetails();
    }
    
  }

  @Output() isLoader = new EventEmitter<boolean>;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

  constructor(private apiService: LFDAPIService, private sanitizer: DomSanitizer, private msgraph: MsGraphService, private router: Router, private authService: CalAngularService, private session: SessionStorageService, private dataservice: DataService, private routes: ActivatedRoute) {
  
  }



}




