import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { CalAngularService } from '@cvx/cal-angular';
import { UserDetails } from 'src/app/core/interfaces/User';
import { Menu, Role } from 'src/app/constants/constants';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-editProfiles',
  templateUrl: './edit-profiles.component.html',
  styleUrls: ['./edit-profiles.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class EditProfilesComponent implements OnInit {
  activeCasesTab() {
    this.isCaseTabActive = true;
    this.isExpTabActive = false;
    this.isSkillTabActive = false;
  }
  activeExperienceTab() {
    this.isCaseTabActive = false;
    this.isExpTabActive = true;
    this.isSkillTabActive = false;
  }
  activeSkillTab() {
    this.isCaseTabActive = false;
    this.isExpTabActive = false;
    this.isSkillTabActive = true;
  }
  user: UserDetails[] = [];
  userphoto: any;
  personId: number = 0;
  // displayName: any;
  // currentUser: any;
  isCaseTabActive = false;
  isExpTabActive = false;
  isSkillTabActive = true;
  userDetails: any;
  
  getUserDetailsByPersonId(personId:any) {
    this.apiService.getUserDetailByPersonId(personId).subscribe(result => {
      if (result.status === 200 && result.body) {
        this.userDetails = result.body;
        this.getAndSetPhotobyEmail(this.userDetails.email);
        
      }

    });
  }
  getAndSetPhotobyEmail(emailAddress: string) {
    this.msgraph.getUserPhotoByEmail(emailAddress).subscribe({
  
      next: data => {
        let jsonKey: string = "";
        new Uint8Array(data).forEach(function (byte: number) { jsonKey += String.fromCharCode(byte) })
        let base64String = btoa(jsonKey);
        localStorage.setItem('photo', base64String);
       
        this.session.setItem("ss_user_photo", this.sanitizer.bypassSecurityTrustUrl(
          'data:image/*;base64, ' + base64String
        ));
        this.userphoto = this.session.getItem("ss_user_photo").changingThisBreaksApplicationSecurity;
        //this.displayPhoto = true;  
      }, 
      error: (err) => {
        this.userphoto = "assets/Images/User.png";
      }
    });
  }

  async ngOnInit() {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.loadUserDetails();
    }
    }
    else{
      this.loadUserDetails();
    }
    
       
  }

  loadUserDetails(){
    this.dataservice.getUserDetailRequest();
    this.dataservice.higlightCurrentMenu(Menu.editprofile);

    let loggedInUserData=this.session.getItem('userdetail');
    
    this.personId = loggedInUserData.personId;
    let paramPersonID=this.routes.snapshot.queryParams['personId'];
     if(paramPersonID){
      let paramPId=parseInt(paramPersonID);
       if(paramPId!=loggedInUserData.personId && loggedInUserData.role!=Role.Admin){
          this.router.navigate(
            ['/unauthorise/error']
          );  
       }
       this.personId=paramPId;

     }
    this.getUserDetailsByPersonId(this.personId); 
  }
  ngAfterViewInit(){
    this.dataservice.higlightCurrentMenu(Menu.editprofile);
  }
  
  @Output() isLoader = new EventEmitter<boolean>;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

  constructor(private apiService: LFDAPIService,private msgraph: MsGraphService ,private sanitizer: DomSanitizer,private router: Router, private authService: CalAngularService, private session: SessionStorageService, private dataservice: DataService,private routes:ActivatedRoute) {


  }



}




