import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DateFormat, OTHERS, OthersType, Role, SUCCESS } from 'src/app/constants/constants';
import { BarAssociation, Education, Experience, ExperienceAndEducation, Membership } from 'src/app/core/interfaces/ExperienceAndEducation';
import { Others } from 'src/app/core/interfaces/SkillsAndExpertise';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-experience-education',
  templateUrl: './experience-education.component.html',
  styleUrls: ['./experience-education.component.less']
})
export class ExperienceEducationComponent {

  isVisibleOthers = false;
  isVisibleInstitution = false;
  isVisibleUniversity = false;
  isVisibleDegree = false;
  isVisibleCountry = false;
  isVisibleSuccess = false
  lblSuccessPopupMessage = '';
  userDetails: any;
  othersTypeName: any = OthersType;
  txtOthersName: string = "";
  othersSuccessMsg = "";
  personId: number = 0;
  isRequiredError = false;
  requiredErrorText = "";
  format: any = DateFormat.yyyyddmm;
  async ngOnInit(): Promise<void> {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.getUserDetails();
    }
    }
    else{
      this.getUserDetails();
    }

  }
  getUserDetails() {
    
    let loggedInUserData = this.session.getItem('userdetail');
    this.personId = loggedInUserData.personId;
    //check query param and update id
    let paramPersonID = this.routes.snapshot.queryParams['personId'];
    if (paramPersonID) {
      let paramPId = parseInt(paramPersonID);
      this.personId = paramPId;
    }
    this.getExperienceTabDropdowndata();
    this.getExperienceAndEducationTabData();
  }
  getExperienceTabDropdowndata() {
    this.isLoader.emit(true);
    this.apiService.getExperienceTabDropdowndata().subscribe({
      next: (res) => {
        this.universityData = [...res.body.universityMaster];
        this.countryData = [...res.body.countryMaster];
        this.degreeData = [...res.body.degreeMaster];
        this.barAssociationData = [...res.body.admissionMaster];
        this.barAssociationData.push({ id: 0, name: "Other" });
        this.degreeData.push({ id: 0, name: "Other" });
        this.countryData.push({ id: 0, name: "Other" });
        this.universityData.push({ id: 0, name: "Other" });
      },
      error: (err) => {
        console.log("Unable to retrieve Case Project Publication TabData", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }
  getExperienceAndEducationTabData() {
    this.isLoader.emit(true);
    this.apiService.getExperienceAndEducationTabData(this.personId).subscribe({
      next: (res) => {
        this.experienceAndEducationList.experienceList = [...res.body.experienceList];
        this.experienceAndEducationList.membershipList = [...res.body.membershipList];
        this.experienceAndEducationList.barAssocaiationList = [...res.body.barAssocaiationList];
        this.experienceAndEducationList.educationList = [...res.body.educationList];

      },
      error: (err) => {
        console.log("Unable to retrieve Case Project Publication TabData", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  @Output() isLoader = new EventEmitter<boolean>;
  constructor(private apiService: LFDAPIService, private dataservice: DataService, private session: SessionStorageService, private router: Router, private routes: ActivatedRoute) {
  }
  saveExperienceAndEducation() {
    this.experienceAndEducationList.personId = this.personId;
    this.apiService.saveExperienceAndEducationDetails(this.experienceAndEducationList).subscribe(output => {
      if (output.body == SUCCESS) {
        this.isVisibleSuccess = true;
        this.lblSuccessPopupMessage = "Record Saved Successfully";
        this.ngOnInit();
      } else {
        //console.log("Unable to save proposal overview");
        this.isVisibleSuccess = true;
        this.lblSuccessPopupMessage = "Something went wrong ,please try again.";
      }
      this.isLoader.emit(false);
    });

  }
  saveEducation() {
    if (!this.isUpdateRecord) {
      if (this.selectedEducation.universityId) {
        //check dublicate name
        if (this.experienceAndEducationList.educationList.filter(x => x.universityId == this.selectedEducation.universityId).length <= 0) {
          this.experienceAndEducationList.educationList.push({ universityId: this.selectedEducation.universityId, universityName: this.selectedEducation.universityName == undefined ? null : this.selectedEducation.universityName, degreeId: this.selectedEducation.degreeId == undefined ? null : this.selectedEducation.degreeId, degreeName: this.selectedEducation.degreeName == undefined ? null : this.selectedEducation.degreeName, major: this.selectedEducation.major == undefined ? null : this.selectedEducation.major, academicAchievements: this.selectedEducation.academicAchievements == undefined ? null : this.selectedEducation.academicAchievements, countryId: this.selectedEducation.countryId == undefined ? null : this.selectedEducation.countryId, countryName: this.selectedEducation.countryName == undefined ? null : this.selectedEducation.countryName, state: this.selectedEducation.state == undefined ? null : this.selectedEducation.state, year: this.selectedEducation.year == undefined ? null : this.selectedEducation.year, id: 0, isActive: true })
          this.isUpdateRecord = false;
          this.isEducationVisible = false;
        }
        else {
          this.isRequiredError = true;
          this.requiredErrorText = "University name already exists!";
        }
      }
      else {
        //
        this.isRequiredError = true;
        this.requiredErrorText = "University is required!";
      }
    }
    else {
      this.isEducationVisible = false;
    }
  }
  cancelEducationPopup() {
    this.isEducationVisible = false;
    this.isRequiredError = false;
  }
  addEducation() {
    this.isEducationVisible = true;
    this.selectedEducation = {} as Education;
    this.isUpdateRecord = false;
  }
  deleteEducation(data: Education) {
    if (data.id > 0) {
      data.isActive = false;
      this.selectedEducation = data;
    }
    else {
      const index = this.experienceAndEducationList.educationList.indexOf(data);
      this.experienceAndEducationList.educationList.splice(index, 1);
    }
  }
  editEducation(data: Education) {
    this.isEducationVisible = true;
    this.selectedEducation = data;
    this.isUpdateRecord = true;
  }
  saveMembership() {
    if (!this.isUpdateRecord) {
      if (this.selectedMembership.institution) {
        //check dublicate name
        if (this.experienceAndEducationList.membershipList.filter(x => x.institution == this.selectedMembership.institution).length <= 0) {
          this.experienceAndEducationList.membershipList.push({ institution: this.selectedMembership.institution, from: this.selectedMembership.from == undefined ? null : this.selectedMembership.from, to: this.selectedMembership.to == undefined ? null : this.selectedMembership.to, id: 0, isActive: true })
          this.isUpdateRecord = false;
          this.isMembershipVisible = false;
        }
        else {
          this.isRequiredError = true;
          this.requiredErrorText = "Institution name already exists!";
        }
      }
      else {
        //
        this.isRequiredError = true;
        this.requiredErrorText = "Institution is required!";
      }
    }
    else {
      this.isMembershipVisible = false;
    }
  }
  cancelMembershipPopup() {
    this.isMembershipVisible = false;
    this.isRequiredError = false;
  }
  RequiredErrorMembership() {
    if (this.selectedMembership.institution) {
      this.isRequiredError = false;
    }
  }
  addMembership() {
    this.isMembershipVisible = true;
    this.selectedMembership = {} as Membership;
    this.isUpdateRecord = false;
  }
  deleteMembership(data: Membership) {
    if (data.id > 0) {
      data.isActive = false;
      this.selectedMembership = data;
    }
    else {
      const index = this.experienceAndEducationList.membershipList.indexOf(data);
      this.experienceAndEducationList.membershipList.splice(index, 1);
    }
  }
  editMembership(data: Membership) {
    this.isMembershipVisible = true;
    this.selectedMembership = data;
    this.isUpdateRecord = true;
  }

  onBarAssocaitionSelected(event: any): void {
    let barIndex = this.barAssociationData.findIndex((item: { id: any; }) => item.id == event);
    if (event != 0) {

      this.selectedBarAssociation.institutionName = this.barAssociationData[barIndex].name;
    }
    else {
      this.selectedBarAssociation.institutionName = "Other"
    }
    //for others
    this.SelectEducationOthers(this.selectedBarAssociation.institutionName, this.othersTypeName.Institution);
    this.isRequiredError = false;
  }

  onDegreeSelected(event: any): void {
    let degreeIndex = this.degreeData.findIndex((item: { id: any; }) => item.id == event);
    if (event != 0)
      this.selectedEducation.degreeName = this.degreeData[degreeIndex].name
    else{
      this.selectedEducation.degreeName = "Other"
    }
    //for others
    this.SelectEducationOthers(this.selectedEducation.degreeName, this.othersTypeName.Degree);
  }

  onCountrySelected(event: any): void {
    let countryIndex = this.countryData.findIndex((item: { id: any; }) => item.id == event);
    if (event != 0)
      this.selectedEducation.countryName = this.countryData[countryIndex].name
    else
      this.selectedEducation.countryName = "Other"
    //for others

    this.SelectEducationOthers(this.selectedEducation.countryName, this.othersTypeName.Country);
  }

  onUniversitySelected(event: any): void {
    let uniIndex = this.universityData.findIndex((item: { id: any; }) => item.id == event);
    if (event != 0)
      this.selectedEducation.universityName = this.universityData[uniIndex].name
    else
      this.selectedEducation.universityName = "Other"
    //for others
    this.SelectEducationOthers(this.selectedEducation.universityName, this.othersTypeName.University);
    this.isRequiredError = false;
  }

  saveBarAssociation() {
    if (!this.isUpdateRecord) {
      if (this.selectedBarAssociation.institutionId) {
        //check dublicate name
        if (this.experienceAndEducationList.barAssocaiationList.filter(x => x.institutionId == this.selectedBarAssociation.institutionId).length <= 0) {
          this.experienceAndEducationList.barAssocaiationList.push({ institutionName: this.selectedBarAssociation.institutionName == undefined ? null : this.selectedBarAssociation.institutionName, admissionDate: this.selectedBarAssociation.admissionDate == undefined ? null : this.selectedBarAssociation.admissionDate, institutionId: this.selectedBarAssociation.institutionId == undefined ? null : this.selectedBarAssociation.institutionId, id: 0, isActive: true })
          this.isUpdateRecord = false;
          this.isBarAssociationVisible = false;
        }
        else {
          this.isRequiredError = true;
          this.requiredErrorText = "Institution name already exists!";
        }
      }
      else {
        //
        this.isRequiredError = true;
        this.requiredErrorText = "Institution is required!";
      }
    }
    else {
      this.isBarAssociationVisible = false;
    }
  }
  cancelBarAssociationPopup() {
    this.isBarAssociationVisible = false;
    this.isRequiredError = false;
  }
  addBarAssociation() {
    this.isBarAssociationVisible = true;
    this.selectedBarAssociation = {} as BarAssociation;
    this.isUpdateRecord = false;
  }
  deleteBarAssociation(data: BarAssociation) {
    if (data.id > 0) {
      data.isActive = false;
      this.selectedBarAssociation = data;
    }
    else {
      const index = this.experienceAndEducationList.barAssocaiationList.indexOf(data);
      this.experienceAndEducationList.barAssocaiationList.splice(index, 1);
    }
  }
  editBarAssociation(data: BarAssociation) {
    this.isBarAssociationVisible = true;
    this.selectedBarAssociation = data;
    this.isUpdateRecord = true;
  }
  saveExperience() {
    if (!this.isUpdateRecord) {
      if (this.selectedExperience.institution) {
        //check dublicate name
        if (this.experienceAndEducationList.experienceList.filter(x => x.institution == this.selectedExperience.institution).length <= 0) {
          this.experienceAndEducationList.experienceList.push({ description: this.selectedExperience.description == undefined ? null : this.selectedExperience.description, from: this.selectedExperience.from == undefined ? null : this.selectedExperience.from, id: this.selectedExperience.id == undefined ? 0 : this.selectedExperience.id, institution: this.selectedExperience.institution == undefined ? null : this.selectedExperience.institution, to: this.selectedExperience.to == undefined ? null : this.selectedExperience.to, isActive: true, role: this.selectedExperience.role == undefined ? null : this.selectedExperience.role })
          this.isUpdateRecord = false;
          this.isExperienceVisible = false;
        }
        else {
          this.isRequiredError = true;
          this.requiredErrorText = "Institution name already exists!";
        }
      }
      else {
        //
        this.isRequiredError = true;
        this.requiredErrorText = "Institution is required!";
      }
    }
    else {
      this.isExperienceVisible = false;
    }
  }
  RequiredErrorExperience() {
    if (this.selectedExperience.institution) {
      this.isRequiredError = false;
    }
  }
  cancelExperiencePopup() {
    this.isExperienceVisible = false;
    this.isRequiredError = false;
  }
  selectedExperience: any;
  isExperienceVisible = false;
  selectedBarAssociation: any;
  isBarAssociationVisible = false;
  selectedMembership: any;
  isMembershipVisible = false;
  selectedEducation: any;
  isEducationVisible = false;
  isUpdateRecord = false;
  lawGroupData: any = []
  barAssociationData: any = []
  countryData: any = []
  degreeData: any = []
  universityData: any = []


  addExperience() {
    this.isExperienceVisible = true;
    this.selectedExperience = {} as Experience;
    this.isUpdateRecord = false;
  }
  deleteExperience(data: Experience) {
    if (data.id > 0) {
      data.isActive = false;
      this.selectedExperience = data;
    }
    else {
      const index = this.experienceAndEducationList.experienceList.indexOf(data);
      this.experienceAndEducationList.experienceList.splice(index, 1);
    }
  }
  editExperience(data: Experience) {
    this.isExperienceVisible = true;
    this.selectedExperience = data;
    this.isUpdateRecord = true;
  }

  experienceAndEducationList: ExperienceAndEducation = ({ barAssocaiationList: [], createdBy: "", educationList: [], experienceList: [], membershipList: [], modifiedBy: "", personId: 0 })

  //#region  Othes Implementaions
  SelectEducationOthers(selected_name: any, othertype: string) {
    //console.log();
    if (selected_name == OTHERS) {
      this.isVisibleInstitution = false;
      this.isVisibleUniversity = false;
      this.isVisibleDegree = false;
      this.isVisibleCountry = false;
      this.isVisibleOthers = false;

      //Coutnry specific
      if (this.othersTypeName.Country === othertype) {
        this.isVisibleCountry = true;
        this.isVisibleOthers = true;
      }

      //Institution 
      if (this.othersTypeName.Institution === othertype) {
        this.isVisibleInstitution = true;
        this.isVisibleOthers = true;
      }

      //Degree 
      if (this.othersTypeName.Degree === othertype) {
        this.isVisibleDegree = true;
        this.isVisibleOthers = true;
      }

      //University 
      if (this.othersTypeName.University === othertype) {
        this.isVisibleUniversity = true;
        this.isVisibleOthers = true;
      }

    };
  }

  SaveOthersEducation(othersType: string) {

    var model = {
      name: this.txtOthersName,
      othersType: othersType,
      useremail: this.session.getItem('useremail')
    }
    this.apiService.addOthers(model).subscribe({
      next: (res) => {
        console.log('treeview' + (res));
        let data = res.body;
        if (data.isExists == 1) {
          this.othersSuccessMsg = "Alredy exists,Please enter other name.";
          this.txtOthersName = '';
        }
        else {
          this.othersSuccessMsg = "Record saved successfully.";
          this.txtOthersName = '';
          this.getExperienceTabDropdowndata();
        }
        setTimeout(() => {
          this.othersSuccessMsg = '';
        }, 3000);
      },
      error: (err) => {
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  CancelOrOKEducationPopup(othersType: string): void {
    this.isVisibleInstitution = false;
    this.isVisibleUniversity = false;
    this.isVisibleDegree = false;
    this.isVisibleCountry = false;
    this.isVisibleOthers = false;
    // country
    if (this.othersTypeName.Country === othersType) {
      this.selectedEducation.countryId = -1;
      //$('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // institution
    if (this.othersTypeName.Institution === othersType) {
      this.selectedBarAssociation.institutionId = -1;
      //$('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // university
    if (this.othersTypeName.University === othersType) {
      this.selectedEducation.universityId = -1;
      //$('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // // degree
    if (this.othersTypeName.Degree === othersType) {
      this.selectedEducation.degreeId = -1;
      //$('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    setTimeout(() => {
      this.othersSuccessMsg = '';
    }, 3000);
  }
  CancelOKSuccessPopup() {
    this.isVisibleSuccess = false;
    this.lblSuccessPopupMessage = '';
  }
  directoryPage() {
    this.router.navigate(
      ['/directory']
    );
  }
  //#endregion

}
