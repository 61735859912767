<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="home-content">
      
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" class="grid-row">
        <div nz-col [nzSpan]="18" [nzPull]="18" [nzPush]="1">
          <!-- </div> -->
          <div nz-col class="custom-ant-col" style="height: 120px;padding:50px;text-align: center;font-weight: bold;background: white;font-size: 20px;line-height: 36px;background: white;">
            Sorry! <br>
            We could not find your profile in Law Function Directory.<br>
            Please make sure your profile exist in Law Function Directory.
          <p></p>
          </div>
        </div>
      </div>

    </div>

  </section>
</main>
