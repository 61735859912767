<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">experience</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Role</th>
                    <th>Employed From</th>
                    <th>Employed To</th>
                    <th>Work Description</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.experienceList">
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.role}}</td>
                    <td *ngIf="data.isActive === true">{{data.from | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.to | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>

                </tr>
            </tbody>
        </nz-table>

    </div>

</div>


<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">bar & court admissions and associations (for attorneys only)</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Admission Date</th>


                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.barAssocaiationList">
                    <td *ngIf="data.isActive === true">{{data.institutionName}}</td>
                    <td *ngIf="data.isActive === true">{{data.admissionDate | date: format}}</td>

                </tr>
            </tbody>
        </nz-table>

    </div>

</div>



<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">memberships, associations, and certifications</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.membershipList">
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.from | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.to | date: format}}</td>
                </tr>
            </tbody>
        </nz-table>
    </div>

</div>


<pre></pre>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-headerView">educational institutions</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>University</th>
                    <th>Degree</th>
                    <th>Major/Concentration</th>
                    <th>Academic Achievements</th>
                    <th>Country</th>
                    <th>State or Province</th>
                    <th>Year Degree Conferred</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.educationList">
                    <td *ngIf="data.isActive === true">{{data.universityName}}</td>
                    <td *ngIf="data.isActive === true">{{data.degreeName}}</td>
                    <td *ngIf="data.isActive === true">{{data.major}}</td>
                    <td *ngIf="data.isActive === true">{{data.academicAchievements}}</td>
                    <td *ngIf="data.isActive === true">{{data.countryName}}</td>
                    <td *ngIf="data.isActive === true">{{data.state}}</td>
                    <td *ngIf="data.isActive === true">{{data.year}}</td>

                </tr>
            </tbody>
        </nz-table>

    </div>

</div>
<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>