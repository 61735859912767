import { Component,EventEmitter, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { GridUSERSAPIM } from 'src/app/core/interfaces/GridAPIM';
import { GridData } from 'src/app/core/interfaces/GridData';
import { PAGE_INDEX, PAGE_SIZE,NO_RESULT, Menu, Role } from 'src/app/constants/constants';
import { UserDetail } from 'src/app/core/interfaces/User';

interface Request {
  id: number;
  title: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  userRoleId: number;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent {
  divText=false;
triggerUserAutoSync() {
  this.divText=true;
  this.apiService.getAutoSyncjobUrl().subscribe({
    next:(res1) =>{
      console.log('URL '+ (res1.body.value));
      
      this.apiService.triggerUsersAutoSyncjob(res1.body.value).subscribe({
        next: (res) =>{
          console.log('Auto sync jon started'+ (res));
          
        alert("Auto Sync Job started!!");
          this.isLoader=false;
        },
        error: (err) =>{
          console.log("Unable to triggerUserAutoSync",err);
          this.isLoader=false;
        },
        complete: () =>{
          this.isLoader=false;
        }
      });
    },
    error: (err) =>{
          console.log("Unable to triggerUserAutoSync",err);
          this.isLoader=false;
        },
        complete: () =>{
          this.isLoader=false;
          
        }
  })
  // this.apiService.triggerUsersAutoSyncjob().subscribe({
  //   next: (res) =>{
  //     console.log('Auto sync jon started'+ (res))
     
  //     this.isLoader=false;
  //   },
  //   error: (err) =>{
  //     console.log("Unable to triggerUserAutoSync",err);
  //     this.isLoader=false;
  //   },
  //   complete: () =>{
  //     this.isLoader=false;
  //   }
  // });
}
  isLoader:boolean = false;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  pageSize:number=PAGE_SIZE;
  pageIndex:number=PAGE_INDEX;
  totalGridRecord:number=0;
  noRecordFound=NO_RESULT;
  searchText:string='';
  public gridDataList:GridData[]=[{
    id:0,
    name:"",
    designation:"",
    emailAddress:"",
    color:"",
    avatar:"",
    totalCount:0,
  }];

  request_id: any;
  openRequestVisible: boolean = false;
  cardBodyStyle = {
    backgroundColor: '#0066b2',
    color: '#FFFFFF',
    // height: '120px'
  };

  constructor(private apiService: LFDAPIService, private router: Router, private session: SessionStorageService, private dataservice: DataService) {
    
  }
  selectedValue: string = "";
  navigateURL: string = "";
  requestsCount: number = 0;
  requestData: Request[] = [];

 async ngOnInit() {
   let result=await this.dataservice.getAuthorizationToken();
    if(result){
      let userdata=this.session.getItem('userdetail') as UserDetail;
      if(userdata?.role!=Role.Admin){
        this.router.navigate(
          ['/unauthorise/error']
        );   
    }
    this.geUsersGridList(this.pageIndex,this.pageSize,this.searchText);
    let userResult=await this.dataservice.getUserDetailRequest();
      if(userResult){
        this.dataservice.higlightCurrentMenu(Menu.admin);
      }
    }
  }
ngAfterViewInit(){
  this.dataservice.higlightCurrentMenu(Menu.admin);
}

geUsersGridList(pageIndex:number,pagSize:number,searchText:string) {
  this.isLoader=true; 
  let gridvm : GridUSERSAPIM ={
   Searchtext:searchText,
   PageNo:pageIndex,
   PageSize:pagSize
  }

  this.apiService.geUsersGridList(gridvm).subscribe({
    next: (res) =>{
      console.log('treeview'+ (res))
      this.gridDataList=[...res.body];
      this.totalGridRecord=this.gridDataList.length > 0? this.gridDataList[0].totalCount:0;
      setTimeout(() => {
        $('.custom-ant-card-body').children().removeClass('ant-card-body');
        $('.custom-ant-card-body').children().addClass('ant-card-body1');
        $('.ant-avatar-string').css('font-size', '40px');
        $('.ant-avatar-string').css('position', 'absolute');
        $('.ant-avatar-string').css('top', '33%');
      }, 0);
      this.isLoader=false;
    },
    error: (err) =>{
      console.log("Unable to retrieve regions",err);
      this.isLoader=false;
    },
    complete: () =>{
      this.isLoader=false;
    }
  });
}


getPageIndexNumber(pageIndex:number){
   this.pageIndex=pageIndex;
   this.geUsersGridList(this.pageIndex,this.pageSize,this.searchText);
}
getPageSizeNumber(pageSize:number){
  this.pageIndex=pageSize;
  this.geUsersGridList(this.pageIndex,this.pageSize,this.searchText);
}

searchGridListData(event:any){
  let searchText=$('#txtgrid').val()?.toString();
  this.searchText=searchText? searchText.toString().trim():"";
  //reset pagesize and pageindex
  this.pageIndex=PAGE_INDEX;
  this.pageSize=PAGE_SIZE;
  this.geUsersGridList(this.pageIndex,this.pageSize,this.searchText);
}

viewDetailsPage(personId:number){
  this.router.navigate(
    ['/edit_profile'],
    { queryParams: { personId: personId } }
  );
}
}
