import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "./session_storage";
import { SkillsAndExpertise } from "../interfaces/SkillsAndExpertise";
import { CaseProjectsNPublications } from "../interfaces/CaseProjectsAndPublications";
import { ExperienceAndEducation } from "../interfaces/ExperienceAndEducation";

@Injectable({
    providedIn: 'root'
  })
export class LFDAPIService{
    
    lfdApiBaseUri: string = environment.apiEndpoint
    authToken: any;
    constructor(private http: HttpClient, private authService: CalAngularService, private session:SessionStorageService) { 
      
    }
    getAuthorization(){
      return new HttpHeaders().set('Authorization','Bearer '+this.session.getItem('token'));
    }
    getAccessToken():  Observable<any> {
      return this.authService.getAADToken();  
    }
    sendEmail():Observable<any>{
        const data = {
            "email": [
                "ramkumarkommoju@chevron.com"
              ]
        }
       return this.http.post(this.lfdApiBaseUri+'email',data);
    }
    sendNotifyReviewerEmail(email: string, approval_team: string, approval_date: string): Observable<HttpResponse<any>>{
      const data = {
          "email": [
            email
            ]
      }
     return this.http.request('POST',this.lfdApiBaseUri+'email?app_team='+approval_team+'&app_date='+approval_date,{body: data ,observe:'response'});
    }
    getLanguage(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_Language', {headers: this.getAuthorization(),observe:'response'});
    }

    getCountry(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_Country', {headers: this.getAuthorization(),observe:'response'});
    }
    getNotary(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_NotaryJurisdiction', {headers: this.getAuthorization(),observe:'response'});
    }
    

    getLawGroup(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_LawGroup', {headers: this.getAuthorization(),observe:'response'});
    }

    getPracticeArea(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_Practice Area', {headers: this.getAuthorization(),observe:'response'});
    }

    getSkills(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_Skill', {headers: this.getAuthorization(),observe:'response'});
    }

    getNotaryJurisdiction(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryMaster/GetDirectoryMaster/M_NotaryJurisdiction', {headers: this.getAuthorization(),observe:'response'});
    }

    saveSkillsAndExpertise(data: SkillsAndExpertise): Observable<HttpResponse<any>>{
      
      return this.http.request('POST',this.lfdApiBaseUri+'SkillAndExpertise/saveSkillAndExpertiseDetails', {headers: this.getAuthorization(),body:data,observe:'response',responseType: 'text'});
    }

    saveCaseProjectPublication(data: CaseProjectsNPublications): Observable<HttpResponse<any>>{
      
      return this.http.request('POST',this.lfdApiBaseUri+'CaseProjectPublication/saveCaseProjectPublicationDetails', {headers: this.getAuthorization(),body:data,observe:'response',responseType: 'text'});
    }

    saveExperienceAndEducationDetails(data: ExperienceAndEducation): Observable<HttpResponse<any>>{
      
      return this.http.request('POST',this.lfdApiBaseUri+'ExperienceAndEducation/saveExperienceAndEducationDetails', {headers: this.getAuthorization(),body:data,observe:'response',responseType: 'text'});
    }


    getSkillAndExpertiseTabData(personId : any): Observable<HttpResponse<any>>{
      
      return this.http.request('Get',this.lfdApiBaseUri+'SkillAndExpertise/GetSkillAndExpertiseTabData/'+personId, {headers: this.getAuthorization(),observe:'response'});
    }

    getCaseProjectPublicationTabData(personId : any): Observable<HttpResponse<any>>{
      
      return this.http.request('Get',this.lfdApiBaseUri+'CaseProjectPublication/getCaseProjectPublicationTabData/'+personId, {headers: this.getAuthorization(),observe:'response'});
    }

    getExperienceAndEducationTabData(personId : any): Observable<HttpResponse<any>>{
      
      return this.http.request('Get',this.lfdApiBaseUri+'ExperienceAndEducation/getExperienceAndEducationTabData/'+personId, {headers: this.getAuthorization(),observe:'response'});
    }

    getExperienceTabDropdowndata(): Observable<HttpResponse<any>>{
      
      return this.http.request('Get',this.lfdApiBaseUri+'ExperienceAndEducation/getExperienceTabDropdowndata', {headers: this.getAuthorization(),observe:'response'});
    }

    getDirectoryTreeViewData(): Observable<HttpResponse<any>>{
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryUsers/GetDirectoryTreeViewData', {headers: this.getAuthorization(),observe:'response'});
    }

    triggerUsersAutoSyncjob(url:any): Observable<HttpResponse<any>>{
      return this.http.request('POST', url, {observe:'response'});
    }

    getAutoSyncjobUrl(): Observable<HttpResponse<any>>{
      return this.http.request('GET', this.lfdApiBaseUri + 'Admin/getAutoSyncjobUrl', { headers: this.getAuthorization() ,observe:'response'});
    }

    gridListData(data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.lfdApiBaseUri+'DirectoryUsers/GridListData', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }
    geUsersGridList(data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.lfdApiBaseUri+'Admin/geUsersGridList', {headers: this.getAuthorization(),body: data ,observe:'response'});
    }

    getUserDetailByEmailId(email:string){
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryUsers/getUserDetailByEmailId/'+email,{headers: this.getAuthorization(),observe:'response'})
    }
    getUserDetailByPersonId(personId:number){
      return this.http.request('GET',this.lfdApiBaseUri+'DirectoryUsers/getUserDetailByPersonId/'+personId,{headers: this.getAuthorization(),observe:'response'})
    }

    getSkillAndExpertiseViewTabData(personId : any): Observable<HttpResponse<any>>{
      
      return this.http.request('Get',this.lfdApiBaseUri+'SkillAndExpertiseView/GetSkillAndExpertiseViewTabData/'+personId, {headers: this.getAuthorization(),observe:'response'});
    }

    addOthers(data: any): Observable<HttpResponse<any>>{
      return this.http.request('POST',this.lfdApiBaseUri+'SkillAndExpertise/saveOthers',{headers: this.getAuthorization(),body:data,observe:'response'})
    }

    getAllUsers(optionalParams?: Record<string, string>): Observable<HttpResponse<any>>{
      let params = new HttpParams();
      const token = this.session.getItem("token");
      let headers = new HttpHeaders().set('authorization',`Bearer ${token}`);
      if (optionalParams) {
        for (const key in optionalParams) {
          if (optionalParams.hasOwnProperty(key)) {
            params = params.set(key, optionalParams[key]);
          }
        }
      }
      return this.http.request('GET',this.lfdApiBaseUri+'get_users?',{headers: headers, params:params, observe:'response'})
    }    
    uploadFile( folder: any, file: any): Observable<HttpEvent<any> | HttpResponse<any>>{
      const formData = new FormData();
      formData.append('file',file);
      formData.append('requestId',folder)
      return this.http.request('POST',this.lfdApiBaseUri+'file',{headers: this.getAuthorization(),body:formData,reportProgress:true,observe:'response'})
    }

}
