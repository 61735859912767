import { Component, ViewChild, EventEmitter, Input, Optional, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { Language } from 'src/app/core/interfaces/Language';
import { GridAPIM } from 'src/app/core/interfaces/GridAPIM';
import { DirectoryTreeViewData } from 'src/app/core/interfaces/DirectoryTreeViewData';

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { GridData } from 'src/app/core/interfaces/GridData';
import { PAGE_INDEX, PAGE_SIZE,NO_RESULT, Menu } from 'src/app/constants/constants';
import { routes } from 'src/app/app-routing.module';

class FilteredTreeResult {
  constructor(
    public treeData: TreeNode[],
    public needsToExpanded: TreeNode[] = []
  ) {}
}

function filterTreeData(data: TreeNode[], value: string): 

FilteredTreeResult {
  const needsToExpanded = new Set<TreeNode>();
  const _filter = (node: TreeNode, result: TreeNode[]): TreeNode[] => {
    if (node.name.search(value) !== -1) {
      result.push(node);
      return result;
    }
    if (Array.isArray(node.children)) {
      const nodes = node.children.reduce(
        (a, b) => _filter(b, a),
        [] as TreeNode[]
      );
      if (nodes.length) {
        const parentNode = { ...node, children: nodes };
        needsToExpanded.add(parentNode);
        result.push(parentNode);
      }
    }
    return result;
  };
  const treeData = data.reduce((a, b) => _filter(b, a), [] as TreeNode[]);
  return new FilteredTreeResult(treeData, [...needsToExpanded]);
}

interface Request {
  id: number;
  title: string;
  status: string;
  createdDate: string;
  updatedDate: string;
  userRoleId: number;
}

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}


interface DirectorsOfficers {
  company_name: string;
  ownership: string;
  ccn: string;
  proposed_changes: string;
  proposed_effective_date: string;
  country: string
}
interface Concurrence {
  reviewer: string;
  concurrence_type: string;
  concurrence_needed_by: string;
  concurred_date: string;
  concurred_by: string;
  comments: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent {

  isLoader:boolean = false;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  passwordVisible = false;
  
   jobtitleName:any= [];
   prtcreaName:any=[];
   skillName:any=[];
   notaryjudName:any=[];
   countrySpecificName:any=[];
   countrySpecificLegalName:any=[];
  //tree view
  treeViewData:DirectoryTreeViewData={
    jobTitleList:[],
    prtcAreaList:[],
    skillsList:[],
    notrJuridictionList:[],
    countrySpecificList:[],
    countryLegalSpecificList:[]
  };
  pageSize:number=PAGE_SIZE;
  pageIndex:number=PAGE_INDEX;
  totalGridRecord:number=0;
  noRecordFound=NO_RESULT;
  searchText:string='';
  public gridDataList:GridData[]=[{
    id:0,
    name:"",
    designation:"",
    emailAddress:"",
    color:"",
    avatar:"",
    totalCount:0,
  }];
  
  TREE_DATA: TreeNode[] = [];
  
  flatNodeMap = new Map<FlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, FlatNode>();
  expandedNodes: TreeNode[] = [];
  searchValue = '';
  originData$ = new BehaviorSubject(this.TREE_DATA);
  searchValue$ = new BehaviorSubject<string>('');

  
  private transformer = (node: TreeNode, level: number): FlatNode => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level,
          };
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  checklistSelection = new SelectionModel<FlatNode>(true);
 

  treeControl = new FlatTreeControl<FlatNode, TreeNode>(
    (node) => node.level,
    (node) => node.expandable,
    {
      trackBy: (flatNode) => this.flatNodeMap.get(flatNode)!,
    }
  );

  treeFlattener = new NzTreeFlattener<TreeNode, FlatNode, TreeNode>(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  filteredData$ = combineLatest([
    this.originData$,
    this.searchValue$.pipe(
      auditTime(300),
      map((value) => (this.searchValue = value?.toLowerCase()))
    ),
  ]).pipe(
    map(([data, value]) =>
      value ? filterTreeData(data, value) : new FilteredTreeResult(data)
    )
  );

  panels = [
    {
      active: false,
      name: 'Job Title',
      model:this.treeViewData.jobTitleList
    },
    {
      active: false,
      name: 'Practice Area',
      model:this.treeViewData.prtcAreaList
    },
    {
      active: false,
      name: 'Skills',
      module:this.treeViewData.skillsList
    },
    {
      active: false,
      name: 'Notary Jurisdiction',
      model:this.treeViewData.notrJuridictionList
    },
  ];
  
  request_id: any;
  openRequestVisible: boolean = false;
  cardBodyStyle = {
    backgroundColor: '#0066b2',
    color: '#FFFFFF',
  };
inputValue: any;

  constructor(private apiService: LFDAPIService, private router: Router, private session: SessionStorageService, private dataservice: DataService) {
  }
 
  selectedValue: string = "";
  navigateURL: string = "";
  requestsCount: number = 0;
  requestData: Request[] = [];

 async ngOnInit() {
    this.isLoader = true;
    let result = await this.dataservice.getAuthorizationToken();
    if(result){
      this.getDirectoryTreeViewData();
      this.getGetGridListData(this.pageIndex,this.pageSize,this.searchText);
      let userResult=await this.dataservice.getUserDetailRequest();
      if(userResult){
       this.dataservice.higlightCurrentMenu(Menu.directory);
      }
      this.isLoader = false;
    }
  }
  
  sendemail(): void {
    this.apiService.sendEmail().subscribe(value => {
      console.log(value);
    });
  }
  onChangeRequest(event: any) {
    this.selectedValue = event;
    this.navigateURL = "/" + event;

  }
  
  ngAfterViewInit(){
    this.dataservice.higlightCurrentMenu(Menu.directory);
  }
  
  
  navigateToRequest( req_id: number){
    console.log(req_id);
    this.router.navigate(['/request',req_id]);
  }
  navigateToPageComponent(data: string){
    this.router.navigate([data]);
  }

  navigateToProposalTypes(){
    this.navigateToPageComponent("proposal types");
  }

   //#region  tree View Code
   hasChild = (_: number, node: FlatNode): boolean => node.expandable;
   hasNoContent = (_: number, node: FlatNode): boolean => (node.name === 'Job Title' || node.name === 'Practice Area'  || node.name === 'Skills'   || node.name === 'Notary Jurisdiction');
   getDirectoryTreeViewData() {
    this.isLoader=true; 
    this.apiService.getDirectoryTreeViewData().subscribe({
      next: (res) =>{
        console.log('treeview'+ (res))
         this.treeViewData.jobTitleList = [...res.body.jobTitleList];
         this.treeViewData.prtcAreaList = [...res.body.prtcAreaList];
         this.treeViewData.skillsList = [...res.body.skillsList];
         this.treeViewData.notrJuridictionList = [...res.body.notrJuridictionList];
         this.treeViewData.countrySpecificList = [...res.body.countrySpecificList];
         this.treeViewData.countryLegalSpecificList = [...res.body.countryLegalSpecificList];

         this.TREE_DATA=[
          {
            name: 'Job Title',
            children:this.treeViewData.jobTitleList 
          },
          {
            name: 'Practice Area',
            children: this.treeViewData.prtcAreaList 
          },
          {
            name: 'Skills',
            children: this.treeViewData.skillsList 
          },
          {
            name: 'Notary Jurisdiction',
            children: this.treeViewData.notrJuridictionList 
          }
          ,
          {
            name: 'Country Specific Knowledge',
            children: this.treeViewData.countrySpecificList 
          }
          ,
          {
            name: 'Country Specific Legal Knowledge',
            children: this.treeViewData.countryLegalSpecificList 
          }

         ];
         this.dataSource.setData(this.TREE_DATA);
         this.originData$ = new BehaviorSubject(this.TREE_DATA);

         this.filteredData$ = combineLatest([
          this.originData$,
          this.searchValue$.pipe(
            auditTime(300),
            map((value) => (this.searchValue = value?.toLowerCase()))
          ),
        ]).pipe(
          map(([data, value]) =>
            value ? filterTreeData(data, value) : new FilteredTreeResult(data)
          )
        );
     
         //filter data  
         this.filteredData$.subscribe((result) => {
          this.dataSource.setData(result.treeData);
    
          const hasSearchValue = !!this.searchValue;
          if (hasSearchValue) {
            if (this.expandedNodes.length === 0) {
              this.expandedNodes = this.treeControl.expansionModel.selected;
              this.treeControl.expansionModel.clear();
            }
            this.treeControl.expansionModel.select(...result.needsToExpanded);
          } else {
            if (this.expandedNodes.length) {
              this.treeControl.expansionModel.clear();
              this.treeControl.expansionModel.select(...this.expandedNodes);
              this.expandedNodes = [];
            }
          }
        });
      },
      error: (err) =>{
        console.log("Unable to retrieve regions",err);
        this.isLoader=false;
      },
      complete: () =>{
        this.isLoader=false;
      }
    });
  }

  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  leafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  itemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

clearAllFilter()
{
   let selectednodes = this.checklistSelection.selected;
   this.checklistSelection.deselect(...selectednodes);
   this.jobtitleName=[];
   this.prtcreaName=[];
   this.skillName=[];
   this.notaryjudName=[];
   this.countrySpecificName=[];
   this.countrySpecificLegalName=[];
   this.getGridListApplyAll();
   $('#txttreesearch').val('');
   this.searchValue$.next('');
}
  
getGetGridListData(pageIndex:number,pagSize:number,searchText:string) {
  this.isLoader=true;
  let gridvm : GridAPIM ={
   Jobtitles: this.jobtitleName,
   Prtcreas:this.prtcreaName,
   Skills:this.skillName,
   Notaryjud:this.notaryjudName,
   CountrySpecific:this.countrySpecificName,
   CountryLegalSpecific:this.countrySpecificLegalName,

   Searchtext:searchText,
   PageNo:pageIndex,
   PageSize:pagSize
  }

  this.apiService.gridListData(gridvm).subscribe({
    next: (res) =>{
      console.log('treeview'+ (res))
      this.gridDataList=[...res.body];
      this.totalGridRecord=this.gridDataList.length > 0? this.gridDataList[0].totalCount:0;
      setTimeout(() => {
        $('.custom-ant-card-body').children().removeClass('ant-card-body');
        $('.custom-ant-card-body').children().addClass('ant-card-body1');
        $('.ant-avatar-string').css('font-size', '40px');
        $('.ant-avatar-string').css('position', 'absolute');
        $('.ant-avatar-string').css('top', '33%');
      }, 0);
      
      console.log(this.gridDataList);
      console.log(res.body);
    },
    error: (err) =>{
      console.log("Unable to retrieve regions",err);
      this.isLoader=false;
    },
    complete: () =>{
      this.isLoader=false;
    }
  });
}

getGridListApplyAll(){
  
  //#region  //clear names 
  this.jobtitleName=[];
   this.prtcreaName=[];
   this.skillName=[];
   this.notaryjudName=[];
   this.countrySpecificName=[];
   this.countrySpecificLegalName=[];
   //#endregion
  let selectednodes = this.checklistSelection.selected;
  selectednodes.forEach(node=>{
    //jobtitle 
    let jobtitle=this.getParentNode(node);
     if(jobtitle?.name=='Job Title'){
      if(node.name!=''){
       this.jobtitleName.push(node.name);
      }
     }
     //practice area
     let prctcarea=this.getParentNode(node);
     if(prctcarea?.name=='Practice Area'){
      if(node.name!=''){
       this.prtcreaName.push(node.name);
      }
     }
    //skills 
     let skill=this.getParentNode(node);
     if(skill?.name=='Skills'){
      if(node.name!=''){ 
      this.skillName.push(node.name);
      }
     }
     //notary 
     let notrjudr=this.getParentNode(node);
     if(notrjudr?.name=='Notary Jurisdiction'){
      if(node.name!=''){ 
      this.notaryjudName.push(node.name);
      }
     }
     let countryspecific=this.getParentNode(node);
     if(countryspecific?.name=='Country Specific Knowledge'){
      if(node.name!=''){ 
      this.countrySpecificName.push(node.name);
      }
     }
     let countrylegalspecific=this.getParentNode(node);
     if(countrylegalspecific?.name=='Country Specific Legal Knowledge'){
       if(node.name!=''){
        this.countrySpecificLegalName.push(node.name);
       }
     }
   });
   this.getGetGridListData(this.pageIndex,this.pageSize,this.searchText);
}

getPageIndexNumber(pageIndex:number){
   this.pageIndex=pageIndex;
   this.getGetGridListData(this.pageIndex,this.pageSize,this.searchText);
}
getPageSizeNumber(pageSize:number){
  this.pageIndex=pageSize;
  this.getGetGridListData(this.pageIndex,this.pageSize,this.searchText);
}

searchGridListData(event:any,isClear:boolean){
 
  let searchText= isClear?'': $('#txtgrid').val()?.toString();
  
  this.searchText=searchText? searchText.toString().trim():"";
  //reset pagesize and pageindex
  this.pageIndex=PAGE_INDEX;
  this.pageSize=PAGE_SIZE;
  this.getGetGridListData(this.pageIndex,this.pageSize,this.searchText);
}

viewDetailsPage(personId:number,mail:string){
  
  const url = this.router.serializeUrl(this.router.createUrlTree(['viewProfile'], { queryParams: { personId: personId,email: mail}  }));
window.open(url, '_blank');
}
 
   //#endregion
}
