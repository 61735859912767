import { UserDetails } from 'src/app/core/interfaces/User';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { DataService } from 'src/app/core/services/data_service';
import { DirectoryMaster } from 'src/app/core/interfaces/DirectoryMaster';
import { CalAngularService } from '@cvx/cal-angular';
import { FormControl, FormGroup, NonNullableFormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { LangProficiency, Skill, PractiseArea, Country, SkillsAndExpertise, NotaryJurisdiction, Others } from 'src/app/core/interfaces/SkillsAndExpertise';
import { OTHERS, OthersType, Role, SUCCESS } from 'src/app/constants/constants';
import { SkillsAndExpertiseView } from 'src/app/core/interfaces/SkillsAndExpertiseView';

@Component({
  selector: 'app-skill-expertise-view',
  templateUrl: './skill-expertise-view.component.html',
  styleUrls: ['./skill-expertise-view.component.less']
})
export class SkillExpertiseViewComponent {
closeCurrentTab() {
  if (confirm("Close the current tab?")) {
    close();
  }
}
  @Output() isLoader = new EventEmitter<boolean>;
  personId: number=0 ;
  user: UserDetails[] = [];
  userphoto: any;
  currentUser: any;
  skillAndExpertiseViewData:SkillsAndExpertiseView = (
    {
      personId: this.personId, langList: [], countrySpecificKnowledgeList: [], countrySpecificLegalKnowledgeList: [], lawGroupId: 0, notaryJurisdictionList: [], practiseAreaList: [], about: "", skillsList: []
    }
  );
  async ngOnInit(): Promise<void> {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.getUserDetails();
    }
    }
    else{
      this.getUserDetails();
    }
    

  }
  constructor(private apiService: LFDAPIService, private router: Router, private authService: CalAngularService, private session: SessionStorageService, private dataservice: DataService, private fb: NonNullableFormBuilder,private routes:ActivatedRoute) {
    this.userphoto = "assets/Images/User.png";//this.dataservice.currentUserPhoto;
    this.currentUser = this.authService.cvxClaimsPrincipal;

  }

  getUserDetails(){
    
    let loggedInUserData=this.session.getItem('userdetail');
    this.personId=loggedInUserData?.personId;
     let paramPersonID=this.routes.snapshot.queryParams['personId'];
     if(paramPersonID){
      let paramPId=parseInt(paramPersonID);
      this.personId=paramPId;
     }
     this.getSkillAndExpertiseViewTabData();

  }

  getSkillAndExpertiseViewTabData() {
    this.isLoader.emit(true);
    this.apiService.getSkillAndExpertiseViewTabData(this.personId).subscribe({
      next: (res) => {
        this.skillAndExpertiseViewData.practiseAreaList = [...res.body.practiseAreaList];
        this.skillAndExpertiseViewData.skillsList = [...res.body.skillsList];
        this.skillAndExpertiseViewData.notaryJurisdictionList = [...res.body.notaryJurisdictionList];
        this.skillAndExpertiseViewData.countrySpecificKnowledgeList = [...res.body.countrySpecificKnowledgeList];
        this.skillAndExpertiseViewData.countrySpecificLegalKnowledgeList = [...res.body.countrySpecificLegalKnowledgeList];
        this.skillAndExpertiseViewData.about = res.body.about;
        this.skillAndExpertiseViewData.lawGroupId = res.body.lawGroupId;
        this.skillAndExpertiseViewData.langList = [...res.body.langList];
      },
      error: (err) => {
        console.log("Unable to retrieve getSkillAndExpertiseTabData - view", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }
}
