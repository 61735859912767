<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
        <nz-alert
        nzType="info"
        nzMessage="Searching for LAW users"
        nzDescription=" "
      ></nz-alert>
      </nz-spin>
    </div>
    <div class="home-content">
      
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          <span class="sub-header">search law member</span>
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" class="grid-row">
        
        <div nz-col [nzSpan]="18" [nzPull]="18" [nzPush]="1">
          <!-- <div nz-col [nzSpan]="10" [nzPush]="1"> -->
            <nz-input-group [nzSuffix]="suffixTemplate" style="width: 60%;">
              <input
                [type]="'text'"
                id="txtgrid"
                nz-input
                placeholder="search emailAddress or name"
                (keyup.enter)="searchGridListData(this)"
              />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <span nz-icon nzType="search" nzTheme="outline" (click)="searchGridListData(this)"></span>
            </ng-template>
          <!-- </div> -->
          <div nz-col class="custom-ant-col" >
          <nz-list nzGrid style="padding:20px">
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="12" *ngFor="let item of gridDataList">
                <nz-list-item>
                  <nz-card class="custom-ant-card-body" (click)="viewDetailsPage(item.id)" style="width:100%;height:105px;" [nzBordered]="true">
                    <div nz-row>
                      <div nz-col [nzSpan]="7" style="padding-left:2px;padding-top: 2px;">
                        <nz-avatar [nzShape]="'square'" style="padding-left: 2px;width: 100%;font-size: 38px;min-height: 94px;" [nzSize]="'large'" [ngStyle]="{ 'background-color': item.color }" [nzText]="item.avatar"></nz-avatar>
                      </div>
                      <div nz-col class="searchGrid" [nzSpan]="16" style="padding-left: 6px;padding-top: 5px;">
                        <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>{{item.name}}</b></p>
                        <p class="custom-ant-card-font-grid">{{item.designation}}</p>
                        <p class="custom-ant-card-font-grid" style="color:#009dd9;z-index:9999;position: absolute;margin-top:-2px !important"><a style="color:#009dd9;text-decoration: none;" href="javascript:void(0)">{{item.emailAddress}}</a></p>
                      </div>
                    </div>
                  </nz-card>
                </nz-list-item>
              </div>
            </div>
          </nz-list>
          <nz-list-empty *ngIf="gridDataList.length <=0">
          {{noRecordFound}}
          </nz-list-empty>
          <p></p>
          <p></p>
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"  (nzPageIndexChange)="getPageIndexNumber($event)" (nzPageSizeChange)="getPageSizeNumber($event)" [nzTotal]="totalGridRecord" [nzSize]="'small'"></nz-pagination>
          </div>
        </div>
        <div nz-col [nzSpan]="6" (click)="triggerUserAutoSync()" style="cursor: pointer;font-weight: bold;">
          <img  style="left: 40%;position:relative;left: 40%;" width="100px" height="100px" src="assets/Images/autoSync.png"  /> <br/> <span style="position: relative;left: 24%;" >Auto sync users from AD group</span>
          <div *ngIf="this.divText" style="color:green;text-align: center;padding: 20px;"><b>Sync job started.You will be notified via email once job completed!</b></div>

        </div>
      </div>

    </div>

  </section>
</main>
