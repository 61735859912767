import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NextPageRoute, NextPageService } from '@cvx/nextpage';
import { CalGuardService } from '@cvx/cal-angular';
import { HomeComponent } from './pages/home/home.component';
import { EditProfilesComponent } from './pages/edit-profiles/edit-profiles.component';
import { SessionStorageService } from './core/services/session_storage';
import { RedirectGuard } from './core/services/RedirectGuard';
import { AdminComponent } from './pages/admin/admin.component';
import { UnAuthoriseComponent } from './pages/unauthorise/unauthorise.component';
import { ViewProfilesComponent } from './pages/view-profiles/view-profiles.component';
import { DataService } from './core/services/data_service';
import { UnAuthoriseUser } from './core/services/UnAuthoriseUser';



export const routes: NextPageRoute[] = [
  {
    path: 'directory',
    title: 'directory',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'edit_profile',
    title: 'edit profile',
    component: EditProfilesComponent,
    canActivate: [CalGuardService,UnAuthoriseUser]
  },
  
  {path: 'orgchart',title: 'law function organization charts',canActivate: [RedirectGuard],component: RedirectGuard,data: {externalUrl: 'https://chevron.sharepoint.com/sites/LFWeb/Shared%20Documents/Forms/AllItems.aspx?id=%2fsites%2fLFWeb%2fShared%20Documents%2fdetailed_orgchart_law.pdf&parent=%2fsites%2fLFWeb%2fShared%20Documents'}},
  {path: 'faqs',title:'faqs',canActivate: [RedirectGuard],component: RedirectGuard,data: {externalUrl: 'https://chevron.imanage.work/work/web/dialogs/link/d/CHEVRONLAW!4491832.1' }},
  { path: '', component: HomeComponent, pathMatch: 'full',canActivate: [CalGuardService] },
  { path: 'unauthorise/error', component: UnAuthoriseComponent, canActivate: [CalGuardService]},
  { path: 'viewProfile', component: ViewProfilesComponent, canActivate: [CalGuardService]},
  { path: 'admin', component: AdminComponent, canActivate: [CalGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private NP: NextPageService,private session: SessionStorageService,private dataservice:DataService) {
    this.NP.options.navigationRoutes = routes;
  } 

 }
