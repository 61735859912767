import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { DirectoryMaster } from 'src/app/core/interfaces/DirectoryMaster';
import { CalAngularService } from '@cvx/cal-angular';
import { UserDetail, UserDetails } from 'src/app/core/interfaces/User';
import { FormControl, FormGroup, NonNullableFormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { LangProficiency, Skill, PractiseArea, Country, SkillsAndExpertise, NotaryJurisdiction, Others } from 'src/app/core/interfaces/SkillsAndExpertise';
import { OTHERS, OthersType, Role, SUCCESS } from 'src/app/constants/constants';

@Component({
  selector: 'app-skill-expertise',
  templateUrl: './skill-expertise.component.html',
  styleUrls: ['./skill-expertise.component.less']
})
export class SkillExpertiseComponent implements OnInit {

  personId: number = 0;
  selectedLangValue1(lng: any) {
    this.selectedlang.label = lng.label
  }
  user: UserDetails[] = [];
  userphoto: any;
  currentUser: any;
  i = 0;
  isVisible = false;
  isVisibleOthers = false;
  isVisibleCountry = false;
  isVisibleNotary = false;
  isVisibleSkills = false;
  isVisiblePracticeArea = false;
  isLanguage = false;
  isVisibleSuccess = false;
  lblSuccessPopupMessage = '';
  othersTypeName: any = OthersType;
  txtOthersName: string = "";
  othersSuccessMsg = "";
  isLanguageError = false;
  LanguageErrorText = "";
  listOfOption = ['Minimal', 'Intermediate', 'Extensive'];

  langProficiencyData: LangProficiency[] = [];

  async ngOnInit(): Promise<void> {

    this.dataservice.getAccessToken();
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.loadDetails();
    }
    }
    else{
      this.loadDetails();
    }

  }

  loadDetails(){
    this.getUserDetails();
    this.getLawGroups();
    this.getLanguages();
    this.getPraciceArea();
    this.getCountry();
    this.getNotary();
    this.getSklills();
    this.langProficiencyData = [];
  }
  @Output() isLoader = new EventEmitter<boolean>;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  passwordVisible = false;
  password?: string;
  request_id: any;
  openRequestVisible: boolean = false;
  cardBodyStyle = {
    backgroundColor: '#0066b2',
    color: '#FFFFFF',
  };
  constructor(private apiService: LFDAPIService, private router: Router, private authService: CalAngularService, private session: SessionStorageService, private dataservice: DataService, private fb: NonNullableFormBuilder, private routes: ActivatedRoute) {
    this.userphoto = "assets/Images/User.png";//this.dataservice.currentUserPhoto;
    this.currentUser = this.authService.cvxClaimsPrincipal;

  }
  selectedLangValue: string = "";
  navigateURL: string = "";
  requestsCount: number = 0;
  requestData: Request[] = [];
  languages: DirectoryMaster[] = [];
  languageData: any[] = [];
  lawGroup: DirectoryMaster[] = [];
  lawGroupData: any[] = [];
  practiceArea: DirectoryMaster[] = [];
  practiceAreaData: any[] = [];
  skills: DirectoryMaster[] = [];
  skillsData: any[] = [];
  country: DirectoryMaster[] = [];
  countryData: any[] = [];
  notary: DirectoryMaster[] = [];
  notaryData: any[] = [];
  selectedlang: any;
  isUpdateRecord = false;
  userDetails: any;
  OtherOption: any = { categoryId: 0, Id: 0, catergoryValue: "Other" };
  skillAndExpertiseDate: SkillsAndExpertise = (
    {
      personId: this.personId, langList: [], countrySpecificKnowledgeList: [], countrySpecificLegalKnowledgeList: [], createdBy: "sainath", creted: new Date(), lawGroupId: 0, notaryJurisdictionList: [], practiseAreaList: [], about: "", skillsList: [], modified: new Date(), modifiedBy: "sainath"
    }
  );
  skillAndExpertiseUserDetails: SkillsAndExpertise = ({
    personId: this.skillAndExpertiseDate.personId, langList: this.skillAndExpertiseDate.langList,
    countrySpecificKnowledgeList: this.skillAndExpertiseDate.countrySpecificKnowledgeList, countrySpecificLegalKnowledgeList: this.skillAndExpertiseDate.countrySpecificLegalKnowledgeList,
    createdBy: "sainath", creted: new Date(), lawGroupId: this.skillAndExpertiseDate.lawGroupId, notaryJurisdictionList: this.skillAndExpertiseDate.notaryJurisdictionList,
    practiseAreaList: this.skillAndExpertiseDate.practiseAreaList, about: this.skillAndExpertiseDate.about, skillsList: this.skillAndExpertiseDate.skillsList,
    modified: new Date(), modifiedBy: "sainath"
  })

  getUserDetails() {

    let loggedInUserData = this.session.getItem('userdetail');
    this.personId = loggedInUserData.personId;
    //check query param and update id
    let paramPersonID = this.routes.snapshot.queryParams['personId'];
    if (paramPersonID) {
      let paramPId = parseInt(paramPersonID);
      this.personId = paramPId;
    }
    this.getSkillAndExpertiseTabData();
    // });   

  }

  getSkillAndExpertiseTabData() {
    this.isLoader.emit(true);
    this.apiService.getSkillAndExpertiseTabData(this.personId).subscribe({
      next: (res) => {
        this.skillAndExpertiseDate.practiseAreaList = [...res.body.practiseAreaList];
        this.skillAndExpertiseDate.skillsList = [...res.body.skillsList];
        this.skillAndExpertiseDate.notaryJurisdictionList = [...res.body.notaryJurisdictionList];
        this.skillAndExpertiseDate.countrySpecificKnowledgeList = [...res.body.countrySpecificKnowledgeList];
        this.skillAndExpertiseDate.countrySpecificLegalKnowledgeList = [...res.body.countrySpecificLegalKnowledgeList];
        this.skillAndExpertiseDate.about = res.body.about;
        this.skillAndExpertiseDate.lawGroupId = res.body.lawGroupId;
        this.skillAndExpertiseDate.langList = [...res.body.langList];
        this.skillAndExpertiseUserDetails = this.skillAndExpertiseDate;
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  getLanguages() {
    this.isLoader.emit(true);
    this.apiService.getLanguage().subscribe({
      next: (out) => {
        this.languages = [...out.body];
        this.languageData = [];
        this.languages.forEach(val => {
          this.languageData.push({
            label: val.name,
            value: val.id,
            checked: false
          });

        });
        this.languageData.push({ label: "Other", value: 0, checked: false });
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  getLawGroups() {
    this.isLoader.emit(true);
    this.apiService.getLawGroup().subscribe({
      next: (out) => {
        this.lawGroup = [...out.body];
        this.lawGroupData = [];
        this.lawGroup.forEach(val => {
          this.lawGroupData.push({
            label: val.name,
            value: val.id

          });
        });
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  getCountry() {
    this.isLoader.emit(true);
    this.apiService.getCountry().subscribe({
      next: (out) => {
        this.country = [...out.body];
        this.countryData = [];
        this.country.forEach(val => {
          this.countryData.push({
            categoryId: Number(val.id),
            Id: 0,//Number(val.id),
            catergoryValue: val.name
          });
        });
        this.countryData.push(this.OtherOption);
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  getSklills() {
    this.isLoader.emit(true);
    this.apiService.getSkills().subscribe({
      next: (out) => {
        this.skills = [...out.body];
        this.skillsData = [];
        this.skills.forEach(val => {
          this.skillsData.push({
            categoryId: Number(val.id),
            Id: 0,//Number(val.id),
            catergoryValue: val.name
          });
        });
        this.skillsData.push(this.OtherOption);
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.categoryId === o2.categoryId : o1 === o2);

  getPraciceArea() {
    this.isLoader.emit(true);
    this.apiService.getPracticeArea().subscribe({
      next: (out) => {
        this.practiceArea = [...out.body];
        this.practiceAreaData = [];
        this.practiceArea.forEach(val => {
          this.practiceAreaData.push({
            categoryId: Number(val.id),
            Id: 0, //Number(val.id),
            catergoryValue: val.name
          });
        });
        this.practiceAreaData.push(this.OtherOption);
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }


  getNotary() {
    this.isLoader.emit(true);
    this.apiService.getNotary().subscribe({
      next: (out) => {
        this.notary = [...out.body];
        this.notaryData = [];
        this.notary.forEach(val => {
          this.notaryData.push({
            categoryId: Number(val.id),
            Id: 0, //Number(val.id),
            catergoryValue: val.name
          });
        });
        this.notaryData.push(this.OtherOption);
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  validateForm: FormGroup<{
    formLayout: FormControl<NzFormLayoutType>;
    fieldA: FormControl<string>;
    filedB: FormControl<string>;
  }> = this.fb.group({
    formLayout: 'horizontal' as NzFormLayoutType,
    fieldA: ['', [Validators.required]],
    filedB: ['', [Validators.required]]
  });

  submitForm(): void {
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  get isHorizontal(): boolean {
    return this.validateForm.controls.formLayout.value === 'horizontal';
  }

  showModal(): void {
    this.isVisible = true;
    this.selectedlang = {} as LangProficiency;
    this.isUpdateRecord = false;
  }

  editLang(dt: LangProficiency): void {
    this.isVisible = true;
    this.selectedlang = dt;
    this.isUpdateRecord = true;
  }

  deleteLang(dt: LangProficiency): void {

    dt.isActive = false;
    this.selectedlang = dt;
  }

  handleOk(): void {
    console.log('Button ok clicked!');
    if (!this.isUpdateRecord) {
      if (this.selectedlang.langId) {
        //check dublicate name
        if (this.skillAndExpertiseDate.langList.filter(x => x.langId == this.selectedlang.langId).length <= 0) {
          this.isVisible = false;
          //this.selectedlang.langvalue = this.selectedlang.langId.label;
          this.skillAndExpertiseDate.langList.push({ langId: this.selectedlang.langId, readingProficiency: this.selectedlang.readingProficiency == undefined ? null : this.selectedlang.readingProficiency, writingProficiency: this.selectedlang.writingProficiency == undefined ? null : this.selectedlang.writingProficiency, speakingProficiency: this.selectedlang.speakingProficiency == undefined ? null : this.selectedlang.speakingProficiency, langvalue: this.selectedlang.langvalue, isActive: true, id: 0 })
        }
        else {
          this.isLanguageError = true;
          this.LanguageErrorText = "Language name already exists!";
        }
      }
      else {
        //
        this.isLanguageError = true;
        this.LanguageErrorText = "Language is required!";
      }
    }
    else {
      this.isVisible = false;
    }

  }

  onSelected(event: any): void {
    let langIndex = this.languageData.findIndex((item: { value: any; }) => item.value == event);
    if (event != 0) {
      this.selectedlang.langvalue = this.languageData[langIndex].label;
    }
    else
      this.selectedlang.langvalue = "Other";
    this.isLanguageError = false;

    //for others
    this.SelectLanguageOthers(this.selectedlang.langvalue, this.othersTypeName.Language);

  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
    this.isLanguageError = false;
  }

  saveSkillandExpertise(): void {
    this.skillAndExpertiseDate.personId = this.personId;
    this.skillAndExpertiseDate.lawGroupId == undefined ? null : this.skillAndExpertiseDate.lawGroupId;
    this.skillAndExpertiseDate.about = this.skillAndExpertiseDate.about == undefined || null ? "" : this.skillAndExpertiseDate.about;
    this.apiService.saveSkillsAndExpertise(this.skillAndExpertiseDate).subscribe({
      next: (output) => {
        if (output.body == SUCCESS) {
          this.isVisibleSuccess = true;
          this.lblSuccessPopupMessage = "Record Saved Successfully";
          this.ngOnInit();
        } else {
          //console.log("Unable to save proposal overview");//
          this.isVisibleSuccess = true;
          this.lblSuccessPopupMessage = "Something went wrong ,please try again.";
        }
        this.isLoader.emit(false);
      },
      error: (err) => {
        console.log("Unable to retrieve regions", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
   
  }

  //#region  Othes Implementaions
  SelectOthers(event: any, othertype: string) {
    //console.log();
    let eventEmitr = event as Others[];
    if (eventEmitr[eventEmitr.length - 1].catergoryValue == OTHERS) {
      this.isVisibleCountry = false;
      this.isVisibleNotary = false;
      this.isVisibleSkills = false;
      this.isVisiblePracticeArea = false;
      this.isVisibleOthers = false;

      //Coutnry specific
      if (this.othersTypeName.Country === othertype) {
        this.isVisibleCountry = true;
        this.isVisibleOthers = true;
      }

      //Notary specific
      if (this.othersTypeName.Notary === othertype) {
        this.isVisibleNotary = true;
        this.isVisibleOthers = true;
      }

      //Skills 
      if (this.othersTypeName.Skills === othertype) {
        this.isVisibleSkills = true;
        this.isVisibleOthers = true;
      }

      //PrticeArea 
      if (this.othersTypeName.PracticeArea === othertype) {
        this.isVisiblePracticeArea = true;
        this.isVisibleOthers = true;
      }

    };
  }
  SelectLanguageOthers(selected_name: any, othertype: string) {
    //console.log();
    if (selected_name == OTHERS) {
      this.isVisibleOthers = false;
      this.isLanguage = false;
      //Language
      if (this.othersTypeName.Language === othertype) {
        this.isLanguage = true;
        this.isVisibleOthers = true;
      }
    };
  }


  SaveOthers(othersType: string) {

    var model = {
      name: this.txtOthersName,
      othersType: othersType,
      useremail: this.session.getItem('useremail')
    }
    this.apiService.addOthers(model).subscribe({
      next: (res) => {
        console.log('treeview' + (res));
        let data = res.body;
        if (data.isExists == 1) {
          this.othersSuccessMsg = "Alredy exists,Please enter other name.";
          this.txtOthersName = '';
        }
        else {
          this.othersSuccessMsg = "Record saved successfully.";
          this.txtOthersName = '';
          if (othersType === this.othersTypeName.Country) {
            this.getCountry();
          }
          if (othersType === this.othersTypeName.Skills) {
            this.getSklills();
          }
          if (othersType === this.othersTypeName.PracticeArea) {
            this.getPraciceArea();
          }
          if (othersType === this.othersTypeName.Notary) {
            this.getNotary();
          }
          if (othersType === this.othersTypeName.Language) {
            this.getLanguages();
          }
        }
        setTimeout(() => {
          this.othersSuccessMsg = '';
        }, 3000);
      },
      error: (err) => {
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  CancelOrOKCountryPopup(othersType: string): void {
    this.isVisibleOthers = false;
    this.isVisibleCountry = false;
    this.isVisibleNotary = false;
    this.isVisibleSkills = false;
    this.isVisiblePracticeArea = false;
    this.isVisibleOthers = false;
    this.isLanguage = false;
    // country
    if (this.othersTypeName.Country === othersType) {
      this.skillAndExpertiseDate.countrySpecificKnowledgeList?.pop();
      $('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // notary
    if (this.othersTypeName.Notary === othersType) {
      this.skillAndExpertiseDate.notaryJurisdictionList?.pop();
      $('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // skills
    if (this.othersTypeName.Skills === othersType) {
      this.skillAndExpertiseDate.skillsList?.pop();
      $('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // PracticeArea
    if (this.othersTypeName.PracticeArea === othersType) {
      this.skillAndExpertiseDate.practiseAreaList?.pop();
      $('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    // Language
    if (this.othersTypeName.Language === othersType) {
      this.selectedlang.langId = 0;
      $('nz-select-item[ng-reflect-label="Other"]').remove();
    }

    setTimeout(() => {
      this.othersSuccessMsg = '';
    }, 3000);
  }
  CancelOKSuccessPopup() {
    this.isVisibleSuccess = false;
    this.lblSuccessPopupMessage = '';
  }
  directoryPage() {
    this.router.navigate(
      ['/directory']
    );
  }
  //#endregion

}
