import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { Menu } from 'src/app/constants/constants';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     if(route?.routeConfig?.path ==Menu.faq){
      let faqulr=localStorage.getItem('faqurl');
      window.open(faqulr!=null?faqulr:'','_blank');
     }
     if(route?.routeConfig?.path==Menu.orgchart){
      let chartulr=localStorage.getItem('orgurl');
      window.open(chartulr!=null?chartulr:'','_blank');
     }
    //window.location.href = route.data['externalUrl'];
    return false;
  }
}
