import { ProjectCase, Publication, CaseProjectsNPublications } from 'src/app/core/interfaces/CaseProjectsAndPublications';
import { Component, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DateFormat, Role, SUCCESS } from 'src/app/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-case-projects-view',
  templateUrl: './case-projects-view.component.html',
  styleUrls: ['./case-projects-view.component.less']
})
export class CaseProjectsViewComponent {
  closeCurrentTab() {
    if (confirm("Close the current tab?")) {
      close();
    }
  }
  @Output() isLoader = new EventEmitter<boolean>;
  constructor(private apiService: LFDAPIService, private session: SessionStorageService, private dataservice: DataService, private fb: FormBuilder, private router: Router, private routes: ActivatedRoute) {
  }

  personId: number = 0;
  userDetails: any;
  format:any=DateFormat.yyyyddmm;
  caseProjectsPublications: CaseProjectsNPublications = (
    {
      personId: this.personId, majorCasesList: [], projectsList: [], publicationsList: [], createdBy: "", modifiedBy: ""
    }
  );
  async ngOnInit(): Promise<void> {
    if(this.session.getItem('token') === null){
      let result = await this.dataservice.getAuthorizationToken();
    if(result){
    this.getUserDetails();
    }
    }
    else{
      this.getUserDetails();
    }


  }
  getUserDetails() {
    let loggedInUserData = this.session.getItem('userdetail');
    this.personId = loggedInUserData?.personId;
    //check query param and update id
    let paramPersonID = this.routes.snapshot.queryParams['personId'];
    if (paramPersonID) {
      let paramPId = parseInt(paramPersonID);
      this.personId = paramPId;
    }
    this.getCaseProjectPublicationTabData();
  }
  getCaseProjectPublicationTabData() {
    this.isLoader.emit(true);
    this.apiService.getCaseProjectPublicationTabData(this.personId).subscribe({
      next: (res) => {
        this.caseProjectsPublications.majorCasesList = [...res.body.majorCasesList];
        this.caseProjectsPublications.projectsList = [...res.body.projectsList];
        this.caseProjectsPublications.publicationsList = [...res.body.publicationsList];
        this.caseProjectsPublications.personId = res.body.personId;
      },
      error: (err) => {
        console.log("Unable to retrieve Case Project Publication TabData", err);
        this.isLoader.emit(false);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    });
  }

  directoryPage(){
    this.router.navigate(
      ['/directory']
    );
  }
}
