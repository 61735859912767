<div nz-col >
    <!-- <div nz-col [nzSpan]="10" [nzPush]="1"> -->
    <div class="sub-header">about my work</div>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div class="divHeader">List any knowledge or experience that could be helpful to your colleagues and keep it current.</div>
            <div>
                <textarea [(ngModel)]=skillAndExpertiseDate.about style="width: 80%;cursor: pointer;"
                    rows="3">{{skillAndExpertiseDate.about}}</textarea>
            </div>
        </div>
        <div nz-col nzSpan="8">
            <div nz-col>
                <div class="divHeader">Law Group</div>
                <nz-select [nzShowSearch]="true"  [(ngModel)]=skillAndExpertiseDate.lawGroupId nzPlaceHolder="find law group">
                    <nz-option *ngFor="let option of lawGroupData" [nzValue]="option.value"
                        [nzLabel]="option.label"></nz-option>
                </nz-select>
            </div>
        </div>
    </div>
    <pre></pre>
    <div nz-row>
        <div nz-col nzSpan="24" class="sub-header">practice areas</div><br>
        <div nz-col nzSpan="24" class="divHeader">Multiple practice areas can be selected. To de-select click again on selected item.
        </div>
        <div nz-col nzSpan="12">

            <nz-select  style="width: 80%;" [compareWith]="compareFn"
                [(ngModel)]=skillAndExpertiseDate.practiseAreaList nzPlaceHolder="Select practice area"
                nzMode="multiple" (ngModelChange)="SelectOthers($event,othersTypeName.PracticeArea)">
                <nz-option *ngFor="let option of practiceAreaData" [nzValue]="option"
                    [nzLabel]="option.catergoryValue"></nz-option>

            </nz-select>
        </div>
    </div>
    <pre></pre>
    <div nz-row>

        <div nz-col nzSpan="24" class="sub-header">skills</div>
        <div nz-col nzSpan="24" class="divHeader">Multiple skills, knowledge areas can be selected</div>
        <div nz-col nzSpan="7">
            <div class="divHeader">Skills</div>
            <div><nz-select [compareWith]="compareFn"  [(ngModel)]=skillAndExpertiseDate.skillsList
                    nzPlaceHolder="Select practice area" nzMode="multiple" (ngModelChange)="SelectOthers($event,othersTypeName.Skills)">
                    <nz-option *ngFor="let option of skillsData" [nzValue]="option"
                        [nzLabel]="option.catergoryValue"></nz-option>
                </nz-select></div>
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="7">
            <div class="divHeader">Country Specific Knowledge</div>
            <div><nz-select [compareWith]="compareFn" [(ngModel)]=skillAndExpertiseDate.countrySpecificKnowledgeList
                    nzPlaceHolder="Select Country Specific Knowledge" nzMode="multiple" (ngModelChange)="SelectOthers($event,othersTypeName.Country)">
                    <nz-option *ngFor="let option of countryData" [nzValue]="option"
                        [nzLabel]="option.catergoryValue"></nz-option>
                </nz-select></div>
        </div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="7">
            <div class="divHeader">Country Specific Legal Knowledge</div>
            <div><nz-select [compareWith]="compareFn"
                    [(ngModel)]=skillAndExpertiseDate.countrySpecificLegalKnowledgeList  (ngModelChange)="SelectOthers($event,othersTypeName.Country)"
                    nzPlaceHolder="Select Country Specific Legal Knowledge" nzMode="multiple">
                    <nz-option *ngFor="let option of countryData" [nzValue]="option"
                        [nzLabel]="option.catergoryValue"></nz-option>
                </nz-select></div>
        </div>
        <div nz-col nzSpan="1"></div>


    </div>
    <pre></pre>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div class="sub-header">notary jurisdictions</div>
            <div class="divHeader">Notary Jurisdictions</div>
            <nz-select style="width: 80%;" [compareWith]="compareFn"
                [(ngModel)]=skillAndExpertiseDate.notaryJurisdictionList nzPlaceHolder="Select notary jurisdictions" (ngModelChange)="SelectOthers($event,othersTypeName.Notary)"
                nzMode="multiple">
                <nz-option *ngFor="let option of notaryData" [nzValue]="option"
                    [nzLabel]="option.catergoryValue"></nz-option>
            </nz-select>
        </div>

    </div>
    <pre></pre>
    <div nz-row id="gridLang">
        <div nz-col nzSpan="24">
            <div class="sub-header">languages</div>
            <nz-table nzTemplateMode [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Speaking  Proficiency</th>
                        <th>Reading Proficiency</th>
                        <th>Writing Proficiency</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of skillAndExpertiseDate.langList">
                        <td *ngIf="data.isActive === true">{{data.langvalue}}</td>
                        <td *ngIf="data.isActive === true">{{data.speakingProficiency}}</td>
                        <td *ngIf="data.isActive === true">{{data.readingProficiency}}</td>
                        <td *ngIf="data.isActive === true">{{data.writingProficiency}}</td>
                        <td *ngIf="data.isActive === true">
                            <img (click)="editLang(data)" class="editTabImage" src="assets/Images/update.png"
                                alt="update" />
                            <nz-divider nzType="vertical"></nz-divider>
                            <img class="editTabImage" src="assets/Images/delete.png" alt="delete"
                                (click)="deleteLang(data)" />
                        </td>
                    </tr>
                </tbody>
            </nz-table>
            <br>
            <img (click)="showModal()" nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
            Language
        </div>

    </div>

</div>

<nz-modal [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()">
    <ng-container *nzModalContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Language</nz-form-label>
        <nz-select #lang (ngModelChange)="onSelected($event)"  [(ngModel)]=selectedlang.langId [nzShowSearch]="true"
            nzPlaceHolder="type text to search or select">
            <nz-option *ngFor="let option of languageData" [nzValue]="option.value"
                [nzLabel]="option.label"></nz-option>
        </nz-select>
        <div *ngIf="isLanguageError" style="height:25px;">
            <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{LanguageErrorText}}</nz-form-label>
        </div>

        <nz-form-label [nzSpan]="24" nzFor="name">Speaking Proficiency</nz-form-label>
        <nz-select #spProficinecy [nzShowSearch]="true" [(ngModel)]=selectedlang.speakingProficiency
            nzPlaceHolder="type text to search or select">
            <nz-option *ngFor="let option of listOfOption" [nzLabel]="option" [nzValue]="option"></nz-option>
            <nz-option [nzLabel]=selectedlang.speakingProficiency [nzValue]=selectedlang.speakingProficiency
                nzHide></nz-option>
        </nz-select>

        <nz-form-label [nzSpan]="24" nzFor="name">Reading Proficiency</nz-form-label>
        <nz-select #rdProficinecy [nzShowSearch]="true" [(ngModel)]=selectedlang.readingProficiency
            nzPlaceHolder="type text to search or select">
            <nz-option *ngFor="let option of listOfOption" [nzLabel]="option" [nzValue]="option"></nz-option>
            <nz-option [nzLabel]=selectedlang.readingProficiency [nzValue]=selectedlang.readingProficiency
                nzHide></nz-option>
        </nz-select>

        <nz-form-label [nzSpan]="24" nzFor="name">Writing Proficiency</nz-form-label>
        <nz-select #wrProficinecy [nzShowSearch]="true" [(ngModel)]=selectedlang.writingProficiency
            nzPlaceHolder="type text to search or select">
            <nz-option *ngFor="let option of listOfOption" [nzLabel]="option" [nzValue]="option"></nz-option>
            <nz-option [nzLabel]=selectedlang.writingProficiency [nzValue]=selectedlang.writingProficiency
                nzHide></nz-option>
        </nz-select>
    </ng-container>
    <ng-container *nzModalFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="handleOk()">Save Details</button>
        <button nz-button (click)="handleCancel()">Cancel</button>
    </ng-container>
</nz-modal>

<nz-row style="float: right;">
    <button style="width: 120px;" nz-button (click)="saveSkillandExpertise()" nzType="primary">Save details</button>&nbsp;
    <button style="width: 120px;margin-left: 20px;" nz-button (click)="directoryPage()" >Cancel</button>
</nz-row>
    

<div id="dvCountry" *ngIf="isVisibleCountry"> 
<nz-modal [(nzVisible)]="isVisibleOthers && isVisibleCountry"  [nzMaskClosable]="false" nzTitle="Country Specific Knowledge"  [nzAutofocus]="null" (nzOnCancel)="CancelOrOKCountryPopup(othersTypeName.Country)">
    
    <ng-container *nzModalContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Country</nz-form-label>
        <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter country" type="text" />
        <br><br>
        <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
      </ng-container>
      <ng-container *nzModalFooter>
        <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthers(othersTypeName.Country)">Save</button>
    </ng-container>
 
</nz-modal>
</div>

<div id="dvNotary" *ngIf="isVisibleNotary"> 
    <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleNotary"  [nzMaskClosable]="false" nzTitle="Notary Jurisdictions"  [nzAutofocus]="null" (nzOnCancel)="CancelOrOKCountryPopup(othersTypeName.Notary)">
        
        <ng-container *nzModalContent>
            <nz-form-label [nzSpan]="24" nzFor="name">Notary Jurisdictions</nz-form-label>
            <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Notary Jurisdictions" type="text" />
            <br><br>
            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
          </ng-container>
          <ng-container *nzModalFooter>
            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthers(othersTypeName.Notary)">Save</button>
        </ng-container>
     
    </nz-modal>
    </div>

    <div id="dvSkills" *ngIf="isVisibleSkills"> 
        <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleSkills"  [nzMaskClosable]="false" nzTitle="Skills"  [nzAutofocus]="null" (nzOnCancel)="CancelOrOKCountryPopup(othersTypeName.Skills)">
            
            <ng-container *nzModalContent>
                <nz-form-label [nzSpan]="24" nzFor="name">Skills</nz-form-label>
                <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Skills" type="text" />
                <br><br>
                <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
              </ng-container>
              <ng-container *nzModalFooter>
                <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthers(othersTypeName.Skills)">Save</button>
            </ng-container>
         
        </nz-modal>
        </div>

        
        <div id="dvPracticeArea" *ngIf="isVisiblePracticeArea"> 
            <nz-modal [(nzVisible)]="isVisibleOthers && isVisiblePracticeArea"  [nzMaskClosable]="false" nzTitle="Practice Areas"  [nzAutofocus]="null" (nzOnCancel)="CancelOrOKCountryPopup(othersTypeName.PracticeArea)">
                
                <ng-container *nzModalContent>
                    <nz-form-label [nzSpan]="24" nzFor="name">Practice Area</nz-form-label>
                    <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Practice Area" type="text" />
                    <br><br>
                    <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
                  </ng-container>
                  <ng-container *nzModalFooter>
                    <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthers(othersTypeName.PracticeArea)">Save</button>
                </ng-container>
             
            </nz-modal>
            </div>

           
                <nz-modal [(nzVisible)]="isVisibleSuccess"  [nzMaskClosable]="false" nzTitle="Message"  [nzAutofocus]="null" (nzOnCancel)="CancelOKSuccessPopup()">
                    <ng-container *nzModalContent>
                        <label id="lblsuccessmsg" class="label-success-msg">{{lblSuccessPopupMessage}}</label>
                      </ng-container>
                      <ng-container *nzModalFooter>
                        <button style="text-align: center;" nz-button nzType="primary" (click)="CancelOKSuccessPopup()">OK</button>
                    </ng-container>
                 
                </nz-modal>
                
                <div id="dvLanguage" *ngIf="isLanguage"> 
                    <nz-modal [(nzVisible)]="isVisibleOthers && isLanguage"  [nzMaskClosable]="false" nzTitle="Language"  [nzAutofocus]="null" (nzOnCancel)="CancelOrOKCountryPopup(othersTypeName.Language)">
                        
                        <ng-container *nzModalContent>
                            <nz-form-label [nzSpan]="24" nzFor="name">Language </nz-form-label>
                            <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Language" type="text" />
                            <br><br>
                            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
                          </ng-container>
                          <ng-container *nzModalFooter>
                            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthers(othersTypeName.Language)">Save</button>
                        </ng-container>
                     
                    </nz-modal>
                    </div>