import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { UserDetail } from '../interfaces/User';
import { SessionStorageService } from './session_storage';
import { DataService } from './data_service';

@Injectable({
    providedIn: 'root'
})
export class UnAuthoriseUser implements CanActivate {

  constructor(private router: Router,private session: SessionStorageService,private dataservice: DataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let userdata=this.session.getItem('userdetail') as UserDetail;
      if(!userdata?.email){
        this.router.navigate(
          ['/unauthorise/error']
        ); 
        let path=route?.routeConfig?.path?route?.routeConfig?.path:"";
        this.dataservice.higlightCurrentMenu(path);
        return false;
      }
    return true;
  }
}
