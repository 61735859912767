// export const STEP_REQUEST_DETAIL = "request detail";
// export const STEP_REVIEW_REQUEST = "review request";
// export const STEP_DISSOLUTION = "dissolution";
// export const STEP_FORMATION = "formation";
// export const STEP_SALE = "sale";
// export const STEP_CONCURRENCE = "concurrence";
// export const STEP_MERGER = "merger";
// export const STEP_OTHER = "other";
// export const GENERAL_DETAILS = "General Details";
// export const PROPOSAL_DETAILS = "Proposal Details";
// export const ASSIGNMENTS = "Assignments";
// export const REVIEW = "Review";
// export const SUBMIT = "Submit";
// export const REQUEST_ID = "";
// export const ACQUISITION_PROPOSAL = "Acquisition";
// export const PROPOSAL_ID = "";



//Grid Constant
export const PAGE_INDEX=1;
export const PAGE_SIZE=6;
export const NO_RESULT="Not Record Found";
export const OTHERS="Other";
export const SUCCESS="Success";

export enum OthersType {
    Country= "country",
    Skills = "skills",
    PracticeArea = "practiceArea",
    Notary = "notary",
    Institution = "institution",
    University = "university",
    Degree = "degree",
    Language = "language",
  }

export enum Role{
   User="User",
   Admin="Admin"
}

export enum Menu{
  directory="directory",
  editprofile="edit profile",
  admin="admin",
  faq="faqs",
  orgchart='orgchart'
}

export enum DateFormat{
  yyyyddmm="yyyy-MM-dd",
  mmddyyyy="MM-dd-yyyy",
}