<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>
<div nz-col>
    <!-- <div nz-col [nzSpan]="10" [nzPush]="1"> -->
    <div class="sub-headerView">about my work</div>
    <div nz-row>
        <div nz-col nzSpan="24" class="divIndent">
                {{skillAndExpertiseViewData.about }}
        </div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="24">
            <div nz-col>
                <div class="sub-headerView">law group</div>
                <div class="divIndent" >{{skillAndExpertiseViewData.lawGroupId}}</div>
            </div>
        </div>
    </div>
    <pre></pre>
    <div nz-row>
        <div nz-col nzSpan="12"  class="sub-headerView">practice areas</div>
        <div nz-col nzSpan="12" class="sub-headerView">skills</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div class="divIndent" *ngFor="let i of skillAndExpertiseViewData.practiseAreaList">{{i}}</div>

        </div>
        <div nz-col nzSpan="12">
           
            <div class="divIndent" *ngFor="let i of skillAndExpertiseViewData.skillsList">{{i}}</div>

        </div>
    </div>
    <pre></pre>
    <div nz-row>

        
        
        <!-- <div nz-col nzSpan="1"></div> -->
        <div nz-col nzSpan="12">
            <div class="sub-headerView">country specific knowledge</div>
            <div class="divIndent" *ngFor="let i of skillAndExpertiseViewData.countrySpecificKnowledgeList">{{i}}</div>
        </div>
        <!-- <div nz-col nzSpan="1"></div> -->
        <div nz-col nzSpan="12">
            <div class="sub-headerView">country specific legal knowledge</div>
            <div class="divIndent" *ngFor="let i of skillAndExpertiseViewData.countrySpecificLegalKnowledgeList">{{i}}</div>
        </div>
        <!-- <div nz-col nzSpan="1"></div> -->


    </div>
    <pre></pre>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div class="sub-headerView">notary jurisdictions</div>
            <div class="divIndent" *ngFor="let i of skillAndExpertiseViewData.notaryJurisdictionList">{{i}}</div>

        </div>

    </div>
    <pre></pre>
    <div nz-row id="gridLang">
        <div nz-col nzSpan="24">
            <div class="sub-headerView">languages</div>
            <nz-table  nzTemplateMode [nzFrontPagination]="false">
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Speaking Proficiency</th>
                        <th>Reading Proficiency</th>
                        <th>Writing Proficiency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of skillAndExpertiseViewData.langList">
                        <td>{{data.langvalue}}</td>
                        <td>{{data.speakingProficiency}}</td>
                        <td>{{data.readingProficiency}}</td>
                        <td>{{data.writingProficiency}}</td>

                    </tr>
                </tbody>
            </nz-table>

        </div>

    </div>

</div>
<button style="text-align: center;float: right;z-index: 100;"  title="Close the current tab" nz-button nzType="primary" (click)="closeCurrentTab()">Close</button>