import { Config } from "../../node_modules/@cvx/cal"

const calConfig: Config = {
  autoSignIn: true,
  popupForLogin: false,
  cacheLocation: "localStorage",
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: "2b67581a-1be1-48fd-940d-a4757e68307f",
  redirectUri: "https://lawdir-test.azure.chevron.com",
  oidcScopes: ["openid", "profile", "email","User.Read","offline_access"],
  graphScopes: ["GroupMember.Read.All", "Directory.Read.All", "Group.Read.All", "User.Read", "User.Read.All"]
}

export const environment = {
  calConfig: calConfig,
  production: false,
  msGraphUrl: "https://graph.microsoft.com/v1.0/",
  apiEndpoint: "https://lawdirtest-api.azurewebsites.net/api/",
};
