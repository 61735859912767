<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <!-- <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu> -->
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
        <nz-alert
        nzType="info"
        nzMessage="Searching for LAW users"
        nzDescription=" "
      ></nz-alert>
      </nz-spin>
    </div>
    <div class="home-content">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24"  [nzPush]="1">
              Welcome to the Law Function Directory where you can search for attorneys and legal professionals as well as colleagues in Law Finance, Human Resources, and the Law Digital Platform.  Use it to find contact information or a needed skill set within the Law Function.
        </div>
      </div>
      <pre></pre>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          Information in the Directory is managed by each Law member.  Members should include their talents, knowledge, and experience, as well as periodically review and <u><a href="https://lawdirectory.chevron.com/edit_profile" style="color: #009dd9;"> edit their profile</a></u> to ensure it remains accurate with relevant information.  If a business job title needs to be updated, please use the identity and access management portal<u><a target="_blank" href="https://idamp.azure.chevron.com/account/selfservice/me" style="color: #009dd9;"> (IdAM)</a></u>.  If you are a member of the Law Function and do not have a profile in the Directory, please submit a <u><a target="_blank" href="https://go.chevron.com/LawApplicationSupport" style="color: #009dd9;">service request</a></u>.  Technical assistance with Directory functionality can also be requested.
        </div>
      </div>
      <pre></pre>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          Law Function Directory functionality will continue to be improved, and your <u><a href="mailto:LawInfoandServices@chevron.com" style="color:#009dd9;">feedback</a> </u> is welcome. 
        </div>
      </div>
      <pre></pre>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          <span class="sub-header">search the law function directory</span>
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" class="grid-row">
        <div nz-col [nzSpan]="6" [nzPush]="1" >
          <span class="sub-header">refine by</span>
          
          <div class="treeview">
            <nz-input-group [nzSuffix]="suffixIcon">
              <input type="text" id="txttreesearch" nz-input placeholder="Search" ngModel (ngModelChange)="searchValue$.next($event)" />
            </nz-input-group>
            <ng-template #suffixIcon>
              <span nz-icon nzType="search"></span>
            </ng-template>
           <div class="row"> 
            <div class="column2"> <a nz-button nzType="link" class="ant-linkbutton" (click)="getGridListApplyAll()" nzBlock>Apply </a> </div>
            <div class="column1"><span  nzType="text" class="span-line" nzBlock> | </span></div>
            <div class="column2"><a nz-button nzType="link" class="ant-linkbutton" (click)="clearAllFilter()" nzBlock>Clear All</a></div>
           </div>
          <div class="treeview-scroll">
          <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" nzVirtualHeight="300px" nzNoAnimation>
            <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodePadding>

              <nz-tree-node-toggle nzTreeNodeNoopToggle></nz-tree-node-toggle>
              
              <nz-tree-node-checkbox *ngIf="node.name!=''"
                [nzChecked]="checklistSelection.isSelected(node)"
                (nzClick)="leafItemSelectionToggle(node)">
              </nz-tree-node-checkbox>
              <nz-tree-node-option (nzClick)="leafItemSelectionToggle(node)">
                {{ node.name }}
              </nz-tree-node-option>

            </nz-tree-node>

            <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodePadding>

              <nz-tree-node-toggle>
                <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
              </nz-tree-node-toggle>
              <nz-tree-node-checkbox
                [nzChecked]="descendantsAllSelected(node)"
                [nzIndeterminate]="descendantsPartiallySelected(node)"
                (nzClick)="itemSelectionToggle(node)">
              </nz-tree-node-checkbox>
              <nz-tree-node-option (nzClick)="itemSelectionToggle(node)">
                {{ node.name }}
              </nz-tree-node-option>
            </nz-tree-node>
          </nz-tree-view>
          </div>
        </div>      
        </div>

        <div nz-col [nzSpan]="18" [nzPull]="18" [nzPush]="1">
            <nz-input-group [nzSuffix]="suffixTemplate" style="width: 60%;">
              <input
                [type]="'text'"
                id="txtgrid"
                nz-input 
                nz-input [(ngModel)]="inputValue"
                placeholder="search law member"
                (keyup.enter)="searchGridListData(this,false)"
              />
            </nz-input-group>
            <ng-template #suffixTemplate>
               <span  nz-icon nzType="search" nzTheme="outline" (click)="searchGridListData(this,false)"></span> 
               <span  nz-icon nzType="close-circle" nzTheme="outline" (click)="searchGridListData(this,true);inputValue=null"></span>
            </ng-template>
          <div nz-col class="custom-ant-col" >
          <nz-list nzGrid style="padding:20px">
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="12" *ngFor="let item of gridDataList">
                <nz-list-item style="overflow: auto;background-color: white;" >
                  <nz-card  class="custom-ant-card-body"  (click)="viewDetailsPage(item.id,item.emailAddress)" style="width:100%;height:105px;" [nzBordered]="true">
                    <div nz-row>
                      <div nz-col [nzSpan]="7" style="padding-left:2px;padding-top: 2px;">
                        <nz-avatar [nzShape]="'square'" style="padding-left: 2px;width: 100%;font-size: 38px;min-height: 94px;" [nzSize]="'large'" [ngStyle]="{ 'background-color': item.color }" [nzText]="item.avatar"></nz-avatar>
                      </div>
                      <div nz-col class="searchGrid" [nzSpan]="16" style="padding-left: 6px;padding-top: 5px;">
                        <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>{{item.name}}</b></p>
                        <p class="custom-ant-card-font-grid">{{item.designation}}</p>
                        <p class="custom-ant-card-font-grid" style="color:#009dd9;z-index:9999;position: relative;margin-top:-2px !important"><u><a style="color:#009dd9;" href=mailto:{{item.emailAddress}}>{{item.emailAddress}}</a></u></p>
                      </div>
                    </div>
                  </nz-card>
                </nz-list-item>
              </div>
            </div>
          </nz-list>
          <nz-list-empty *ngIf="gridDataList.length <=0">
          {{noRecordFound}}
          </nz-list-empty>
          <p></p>
          <p></p>
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"  (nzPageIndexChange)="getPageIndexNumber($event)" (nzPageSizeChange)="getPageSizeNumber($event)"  [nzTotal]="totalGridRecord" [nzSize]="'small'" style="text-align: center;" ></nz-pagination>
          </div>
        </div>
      </div>

    </div>

  </section>
</main>
