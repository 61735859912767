import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { LFDAPIService } from './core/services/api';
import { MsGraphService } from './core/services/ms-graph';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavigationLocation, NextPageService, PageLayout, SearchType, SliderType } from '@cvx/nextpage';
import { SessionStorageService } from './core/services/session_storage';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DataService } from './core/services/data_service';
import { UserDetail, keyvalue } from './core/interfaces/User';
import { Menu } from './constants/constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  isCollapsed = true;
  currentYear = new Date().getFullYear();
  userName: string = '';
  userphoto!: any;
  displayPhoto = false;
  role_id!: number;
  currentUserProfile!: ICvxClaimsPrincipal;
  request_id: string | undefined;
  visible = false;
  isAuthorizedUser = false;
  isInvalidUser = false;
  userData:any;
  keyvaluelist:keyvalue[]=[];
  // Session time out
  private sessionTimeoutInSeconds = 30; // Session timeout duration in seconds
  private userActivity$ = new Subject<void>();
  private timer$: any;
  private destroy$ = new Subject<void>();

  constructor(private NP: NextPageService, private authService: CalAngularService, private msgraph: MsGraphService,
    private sanitizer: DomSanitizer, private apiService: LFDAPIService, private router: Router,
    private session: SessionStorageService, private modal: NzModalService, private dataService: DataService) {
    this.NP.options.sitename = 'Law Function Directory';
    this.startTimer();
    this.initUserActivityTracking();
    this.handleSessionTimeout();

  }
  
  private startTimer(): void {
    //this.timer$ = timer(0, 3600000)
   
    this.timer$ = timer(0,600000)
      .pipe(
        takeUntil(this.destroy$)
      );
  }
  private initUserActivityTracking(): void {
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
  }

  private resetTimer(): void {
    this.userActivity$.next();
  }

  private handleSessionTimeout(): void {
    this.timer$.subscribe((seconds: number) => {
      //alert("handleSessionTimeout" + seconds);
      const remainingTime = this.sessionTimeoutInSeconds - seconds;
      if (remainingTime <= 0) {
        this.logout();
      }
    });
  }

  private logout(): void {
    this.dataService.getAccessToken();
      location.reload();
    console.log("user logged out!")
    // this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  
  
  async ngOnInit(){

    this.authService.getUserPropertiesFromMsGraph(["givenName","cai", "jobTitle","photo","surname"]).subscribe( async graphProp => {
      this.userName = graphProp.givenName;
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      let result = await this.dataService.getAuthorizationToken();
      if(result){
      this.session.setItem("useremail",this.currentUserProfile.email);
      this.dataService.getAccessToken();
      this.apiService.getUserDetailByEmailId(this.currentUserProfile.email).subscribe(result => {
        if (result.status === 200 && result.body) {
          this.userData=result.body;
          this.dataService.userDetails = this.userData.userDetails as UserDetail;
          this.keyvaluelist=this.userData.keyValueList;
          if(this.keyvaluelist){
              
              let faqIndex=this.keyvaluelist.findIndex(item => item.key == Menu.faq);
              if(faqIndex >=0){
                localStorage.setItem('faqurl',this.keyvaluelist[faqIndex].value);
              }
              let orgIndex=this.keyvaluelist.findIndex(item => item.key == Menu.orgchart);
              if(orgIndex >=0){
                localStorage.setItem('orgurl',this.keyvaluelist[orgIndex].value);
              }
          }
          if (this.dataService.userDetails?.email) {
            this.session.setItem("userdetail", this.dataService.userDetails);
          }
        }
       });
      }

    });
    this.authService.getAADToken().subscribe(result => {
      this.session.setItem('token', JSON.parse(JSON.stringify(result)))
      this.getAndSetPhoto();
    });


  }

  getAndSetPhoto() {
    this.msgraph.getUserPhoto().subscribe(data => {
      let jsonKey: string = "";
      new Uint8Array(data).forEach(function (byte: number) { jsonKey += String.fromCharCode(byte) })
      let base64String = btoa(jsonKey);
      localStorage.setItem('photo', base64String);
      this.userphoto = this.sanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64, ' + base64String
      );
      this.session.setItem("ss_user_photo", this.userphoto);
      this.displayPhoto = true;
    });
  }


}
