import { Component, EventEmitter, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';

@Component({
  selector: 'app-unauthorise',
  templateUrl: './unauthorise.component.html',
  styleUrls: ['./unauthorise.component.less']
})
export class UnAuthoriseComponent {
  @Output() isLoader = new EventEmitter<boolean>;
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

  constructor(private apiService: LFDAPIService, private router: Router, private session: SessionStorageService, private dataservice: DataService) {
    
  }
 
  ngOnInit() {
    this.dataservice.getAccessToken();
  }

}
