<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">major cases & transactions</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Case/Transaction Name</th>
                    <th>Work Description</th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.majorCasesList">
                    <td *ngIf="data.isActive === true">{{data.name}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editMajorCase(data)" class="editTabImage" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img class="editTabImage" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteMajorCase(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addMajorCase()" class="sub-header" style="font-size: 15px; cursor: pointer;" ><img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" />Add
            major Case or transactions</div>
    </div>

</div>

<nz-modal nzCentered="true"  [nzContent]="modalContent" [nzFooter]="modalFooter"
    [(nzVisible)]="isMajorCaseVisible" (nzOnCancel)="cancelMajorCasePopup()">
    <ng-template #modalContent>
        <nz-form-label >Case/Transaction Name</nz-form-label>
        <nz-form-control>
            <input nz-input placeholder="Case/Transaction Name" 
                [(ngModel)]=selectedMajorCase.name value={{selectedMajorCase.name}}  (blur)="RequiredErrorTransationName()" />
        </nz-form-control>
        <div *ngIf="isRequiredError" style="height:25px;">
            <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
          </div>
        <nz-form-label [nzSpan]="18" nzFor="name">Work Description</nz-form-label>
        <textarea [nzAutosize]="true" [(ngModel)]=selectedMajorCase.description style="width: 100%;"
            rows="3">{{selectedMajorCase.description}}</textarea>
    </ng-template>
    <ng-template #modalFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveMajorCase()">Save</button>
    </ng-template>
</nz-modal>

<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">project & initiatives</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Project</th>
                    <th>Work Description</th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.projectsList">
                    <td *ngIf="data.isActive === true">{{data.name}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editProject(data)" class="editTabImage" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img class="editTabImage" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteProject(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addProject()" class="sub-header" style="font-size: 15px; cursor: pointer;" > <img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
        project or initiative </div>
    </div>

</div>

<nz-modal nzCentered="true" [nzContent]="modalProjectContent" [nzFooter]="modalProjectFooter" [(nzVisible)]="isProjectVisible"
    (nzOnCancel)="cancelProjectPopup()">
    <ng-template #modalTitle>Add project or initiative</ng-template>
    <ng-template #modalProjectContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Project</nz-form-label>
        <input nz-input placeholder="project or initiative" [(ngModel)]="selectedProject.name"
            value={{selectedProject.name}} (blur)="RequiredErrorProjectInitiative()" />
        <div *ngIf="isRequiredError" style="height:25px;">
           <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
        </div>
        <nz-form-label [nzSpan]="24" nzFor="name">Work Description</nz-form-label>
        <textarea [(ngModel)]=selectedProject.description style="width: 100%;"
            rows="3">{{selectedProject.description}}</textarea>
    </ng-template>
    <ng-template #modalProjectFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveProject()">Save</button>
    </ng-template>
</nz-modal>
<pre></pre>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">publications</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Institution</th>
                    <th>Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of caseProjectsPublications.publicationsList">
                    <td *ngIf="data.isActive === true"><span *ngIf="data?.link?.length === null || data?.link?.length === 0">{{data.title}}</span> <a *ngIf="(data?.link?.length || 0 < 0) && data?.link?.indexOf('http')!==-1" target="_blank" href="{{data.link}}">{{data.title}}</a><a *ngIf="(data?.link?.length || 0 < 0) && data?.link?.indexOf('http')==-1" target="_blank" href="{{'//'+ data.link}}">{{data.title}}</a> </td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.publishedDate | date: format }}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editPublications(data)" class="editTabImage" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img class="editTabImage" src="assets/Images/delete.png" alt="delete"
                            (click)="deletePublications(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addPublications()" class="sub-header" style="font-size: 15px; cursor: pointer;" > <img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
        Publication </div>
    </div>

</div>
<nz-modal nzCentered="true" [nzContent]="modalPublicationContent" [nzFooter]="modalPublicationFooter" [(nzVisible)]="isPublicationVisible"
    (nzOnCancel)="cancelPublicationPopup()">
    <ng-template #modalTitle>Add publication</ng-template>
    <ng-template #modalPublicationContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Title</nz-form-label>
        <input nz-input placeholder="Title" [(ngModel)]="selectedPublication.title"
            value="{{selectedPublication.title}}" (blur)="RequiredErrorTitle()" />
        <div *ngIf="isRequiredError" style="height:25px;">
          <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
        </div>
        <nz-form-label [nzSpan]="24" nzFor="name">Publication Description</nz-form-label>
        <textarea [(ngModel)]=selectedPublication.description style="width: 100%;"
            rows="3">{{selectedPublication.description}}</textarea>

        <nz-form-label [nzSpan]="24" nzFor="name">Publishing Institution</nz-form-label>
        <input nz-input placeholder="Publishing Institution" [(ngModel)]="selectedPublication.institution"
            value="{{selectedPublication.institution}}" />

        <nz-form-label [nzSpan]="24" nzFor="name">Publication Date</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="Publication Date" nzspan="24"
                [(ngModel)]="selectedPublication.publishedDate">
                {{selectedPublication.publishedDate}}</nz-date-picker>
        </nz-space>

        <nz-form-label [nzSpan]="24" nzFor="name">Publication Link</nz-form-label>
        <input  nz-input placeholder="Publication Link" [(ngModel)]="selectedPublication.link"
            value="{{selectedPublication.link}}" />
    </ng-template>
    <ng-template #modalPublicationFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="savePublication()">Save</button>
    </ng-template>
</nz-modal>

<nz-row style="float: right;">
    <button style="width: 120px;"  nz-button (click)="saveCaseProjectPublication()" nzType="primary">Save Details</button>&nbsp;
    <button style="width: 120px;margin-left: 20px;"  nz-button (click)="directoryPage()">Cancel</button>
</nz-row>

<nz-modal [(nzVisible)]="isVisibleSuccess"  [nzMaskClosable]="false" nzTitle="Message"  [nzAutofocus]="null" (nzOnCancel)="CancelOKSuccessPopup()">
    <ng-container *nzModalContent>
        <label id="lblsuccessmsg" class="label-success-msg">{{lblSuccessPopupMessage}}</label>
      </ng-container>
      <ng-container *nzModalFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="CancelOKSuccessPopup()">OK</button>
    </ng-container>
 
</nz-modal>
