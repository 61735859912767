<main class="container">
  <section id="column2" npSetLayout [pageLayout]="PageLayout.Layout12">
    <!-- <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu> -->
  </section>
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="home-content">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top" class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24">
          Welcome to the Law Directory Profile Editor where Law colleagues can edit their profile without the need to
          submit a ticket. Use the editor below or open the Profile Editor in a new tab. </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top" class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24">
          ​​​​​​​Please provide your feedback to <u><a style="color: #009dd9;"
              href="mailto:Lawinfoandservices@chevron.com" target="_blank"
              title="mailto:Lawinfoandservices@chevron.com">Lawinfoandservices@chevron.com</a></u>, so we can focus on
          improving what is most important for you.
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top" class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="24">
          For technical assistance, please submit a <u><a style="color: #009dd9;"
              href="https://go.chevron.com/lawitrequest" target="_blank"
              title="https://go.chevron.com/lawitrequest">service request</a></u>.
        </div>
      </div>
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top" class="grid-row" nzJustify="start">

      </div>
      <div nz-row   style="border: 1px solid black;border-left:none ;">
        <div nz-col style="width: 21%;float: left;" class="mianContent">
          <span class="sub-header"></span>
          <div>
            <nz-avatar class="userImage" nzSrc="{{userphoto}}"></nz-avatar>
            <!-- <img  nz-image nzSrc="{{userphoto}}" alt="photo" /> -->
          </div>
          <div class="userDetails"><b>{{userDetails?.name}}</b></div>
          <div class="userDetails">{{userDetails?.jobTitle}}</div>
          <div class="userDetails">{{userDetails?.lawGroup}}</div>
          <div class="user">{{userDetails?.address}}</div>
          <div class="user">{{userDetails?.telephone?.length || 0 > 0 ? '(O)'+userDetails?.telephone :''}}{{
            ((userDetails?.mobile?.length || 0 > 0) && (userDetails?.telephone?.length || 0 > 0)) ? '/':'' }}{{ userDetails?.mobile?.length || 0
            > 0 ? ''+userDetails?.mobile+ '
            (M)':''}}</div>
          <div class="user"><u><a style="color:#009dd9;"
                href=mailto:{{userDetails?.email}}>{{userDetails?.email}}</a></u></div>
          <pre></pre>
          <div (click)="activeSkillTab()" class="leftNav"
            [ngStyle]="{'color': isSkillTabActive ? 'white' : 'black','background-color': isSkillTabActive ?'#009dd9' :'white'}">
            skills & expertise</div>
          <div (click)="activeExperienceTab()" class="leftNav"
            [ngStyle]="{'color': isExpTabActive ? 'white' : 'black','background-color': isExpTabActive ?'#009dd9' :'white'}">
            experience & education</div>
          <div (click)="activeCasesTab()" class="leftNav" style="border-bottom: 0.5px solid black;"
            [ngStyle]="{'color': isCaseTabActive ? 'white' : 'black','background-color': isCaseTabActive ?'#009dd9' :'white'}">
            cases, projects & publications</div>
        </div>
        <div><mat-divider style="box-shadow: 3px 3px 3px black;" vertical style="height:100%"></mat-divider></div>
        <div nz-col  style="padding: 14px;float: left;width: 78%;">
          <app-skill-expertise *ngIf="isSkillTabActive" ></app-skill-expertise> 
          <app-experience-education *ngIf="isExpTabActive"></app-experience-education>
          <app-case-projects *ngIf="isCaseTabActive"></app-case-projects>
        </div>
      </div>
    </div>


  </section>
</main>