<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">experience</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Role</th>
                    <th>Employed From</th>
                    <th>Employed To</th>
                    <th>Work Description</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.experienceList">
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.role}}</td>
                    <td *ngIf="data.isActive === true">{{data.from | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.to | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.description}}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editExperience(data)" style="width: 30%;" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img style="width: 30%;" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteExperience(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addExperience()" class="sub-header" style="font-size: 15px; cursor: pointer;"> <img nz-button
                style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add work experience
        </div>
    </div>

</div>

<nz-modal nzCentered="true" [nzContent]="modalContent" [nzFooter]="modalFooter" [(nzVisible)]="isExperienceVisible"
    (nzOnCancel)="cancelExperiencePopup()">
    <ng-template #modalContent>
        <nz-form-label [nzSpan]="18" nzFor="name">Institution</nz-form-label>
        <input nzrequired nz-input placeholder="Institution" [(ngModel)]="selectedExperience.institution"
            value="{{selectedExperience.institution}}" (blur)="RequiredErrorExperience()"/>
        <div *ngIf="isRequiredError" style="height:25px;">
                <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
        </div>
        <nz-form-label [nzSpan]="18" nzFor="name">Role</nz-form-label>
        <input nz-input placeholder="Role" [(ngModel)]="selectedExperience.role" value="{{selectedExperience.role}}" />

        <nz-form-label [nzSpan]="24" nzFor="name">Employed From</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="12/31/2001" nzspan="24"
                [(ngModel)]="selectedExperience.from">
                {{selectedExperience.from}}</nz-date-picker>
        </nz-space>

        <nz-form-label [nzSpan]="24" nzFor="name">Employed To</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="12/31/2001" nzspan="24"
                [(ngModel)]="selectedExperience.to">
                {{selectedExperience.to}}</nz-date-picker>
        </nz-space>

        <nz-form-label [nzSpan]="24" nzFor="name">Work Description</nz-form-label>
        <textarea [nzAutosize]="true" style="width: 100%;" rows="3"
            [(ngModel)]=selectedExperience.description>{{selectedExperience.description}}</textarea>
    </ng-template>
    <ng-template #modalFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveExperience()">Save</button>
    </ng-template>
</nz-modal>

<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">bar & court admissions and associations (for attorneys only)</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Admission Date</th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.barAssocaiationList">
                    <td *ngIf="data.isActive === true">{{data.institutionName}}</td>
                    <td *ngIf="data.isActive === true">{{data.admissionDate | date: format}}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editBarAssociation(data)" style="width: 30%;" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img style="width: 30%;" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteBarAssociation(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addBarAssociation()" class="sub-header" style="font-size: 15px; cursor: pointer;"> <img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
        admission </div>
    </div>

</div>

<nz-modal nzCentered="true" [nzContent]="modalProjectContent" [nzFooter]="modalProjectFooter"
    [(nzVisible)]="isBarAssociationVisible" (nzOnCancel)="cancelBarAssociationPopup()">
    <ng-template #modalTitle>Add admission</ng-template>
    <ng-template #modalProjectContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Institution</nz-form-label>
        <nz-select (ngModelChange)="onBarAssocaitionSelected($event)" [(ngModel)]=selectedBarAssociation.institutionId [nzShowSearch]="true"
            nzPlaceHolder="type text to search or select">
            <nz-option    *ngFor="let option of barAssociationData" [nzValue]="option.id"
                [nzLabel]="option.name"></nz-option>

        </nz-select>
        <div *ngIf="isRequiredError" style="height:25px;">
            <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
          </div>
         
        <nz-form-label [nzSpan]="24" nzFor="name">Admission Date</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="12/31/2001" nzspan="24"
                [(ngModel)]="selectedBarAssociation.admissionDate">
                {{selectedBarAssociation.admissionDate}}</nz-date-picker>
        </nz-space>


    </ng-template>
    <ng-template #modalProjectFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveBarAssociation()">Save</button>
    </ng-template>
</nz-modal>

<pre></pre>

<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">memberships, associations, and certifications</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>Institution</th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.membershipList">
                    <td *ngIf="data.isActive === true">{{data.institution}}</td>
                    <td *ngIf="data.isActive === true">{{data.from | date: format}}</td>
                    <td *ngIf="data.isActive === true">{{data.to | date: format}}</td>

                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editMembership(data)" style="width: 30%;" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img style="width: 30%;" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteMembership(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addMembership()" class="sub-header" style="font-size: 15px; cursor: pointer;"><img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
        membership, association, or certification
    </div></div>

</div>

<nz-modal nzCentered="true" [nzContent]="modalMembershipContent" [nzFooter]="modalMembershipFooter"
    [(nzVisible)]="isMembershipVisible" (nzOnCancel)="cancelMembershipPopup()">
    <ng-template #modalTitle>Add membership, association, or certification</ng-template>
    <ng-template #modalMembershipContent>
        <nz-form-label [nzSpan]="24" nzFor="name">Institution</nz-form-label>
        <input nz-input placeholder="project or initiative" [(ngModel)]="selectedMembership.institution"
            value="{{selectedMembership.institution}}" (blur)="RequiredErrorMembership()"/>
            <div *ngIf="isRequiredError" style="height:25px;">
                <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
            </div>
        <nz-form-label [nzSpan]="24" nzFor="name">Valid From (if applicable)</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="12/31/2001" nzspan="24"
                [(ngModel)]="selectedMembership.from">
                {{selectedMembership.from}}</nz-date-picker>
        </nz-space>

        <nz-form-label [nzSpan]="24" nzFor="name">Valid To (if applicable)</nz-form-label>
        <nz-space nzDirection="vertical" style="width: 100%">
            <nz-date-picker style="width: 100%" placeholder="12/31/2001" nzspan="24"
                [(ngModel)]="selectedMembership.to">
                {{selectedMembership.to}}</nz-date-picker>
        </nz-space>
    </ng-template>
    <ng-template #modalMembershipFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveMembership()">Save</button>
    </ng-template>
</nz-modal>
<pre></pre>
<div nz-row>
    <div nz-col nzSpan="24">
        <div class="sub-header">educational institutions</div>
        <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                    <th>University</th>
                    <th>Degree</th>
                    <th>Major</th>
                    <th>Achievements</th>
                    <th>Country</th>
                    <th>State</th>
                    <th>Year</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of experienceAndEducationList.educationList">
                    <td *ngIf="data.isActive === true">{{data.universityName}}</td>
                    <td *ngIf="data.isActive === true">{{data.degreeName}}</td>
                    <td *ngIf="data.isActive === true">{{data.major}}</td>
                    <td *ngIf="data.isActive === true">{{data.academicAchievements}}</td>
                    <td *ngIf="data.isActive === true">{{data.countryName}}</td>
                    <td *ngIf="data.isActive === true">{{data.state}}</td>
                    <td *ngIf="data.isActive === true">{{data.year}}</td>
                    <td style="width: 10%;" *ngIf="data.isActive === true">
                        <img (click)="editEducation(data)" style="width: 30%;padding-bottom: 10px;" src="assets/Images/update.png"
                            alt="update" />
                        <nz-divider nzType="vertical"></nz-divider>
                        <img style="width: 30%;" src="assets/Images/delete.png" alt="delete"
                            (click)="deleteEducation(data)" />
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <br>
        <div (click)="addEducation()" class="sub-header" style="font-size: 15px; cursor: pointer;"> <img  nz-button style="width: 4%;" src="assets/Images/add.png" alt="add" /> Add
        education</div>
    </div>

</div>
<nz-modal nzCentered="true" [nzContent]="modalEducationContent" [nzFooter]="modalEducationFooter"
    [(nzVisible)]="isEducationVisible" (nzOnCancel)="cancelEducationPopup()">
    <ng-template #modalTitle>Add publication</ng-template>
    <ng-template #modalEducationContent>


        <nz-form-label [nzSpan]="24" nzFor="name">University</nz-form-label>
        <nz-select (ngModelChange)="onUniversitySelected($event)" [(ngModel)]=selectedEducation.universityId nzPlaceHolder="find university" [nzShowSearch]="true">
            <nz-option *ngFor="let option of universityData" [nzValue]="option.id"
                [nzLabel]="option.name"></nz-option>
        </nz-select>
        <div *ngIf="isRequiredError" style="height:25px;">
            <nz-form-label [nzSpan]="24" nzFor="name" class="label-required">{{requiredErrorText}}</nz-form-label>
        </div>

        <nz-form-label [nzSpan]="24" nzFor="name">Degree</nz-form-label>
        <nz-select (ngModelChange)="onDegreeSelected($event)" [(ngModel)]=selectedEducation.degreeId nzPlaceHolder="find degree" [nzShowSearch]="true">
            <nz-option *ngFor="let option of degreeData" [nzValue]="option.id"
                [nzLabel]="option.name"></nz-option>
        </nz-select>
        <nz-form-label [nzSpan]="24" nzFor="name">Major/Concentration</nz-form-label>
        <input nz-input placeholder="Major/Concentration" [(ngModel)]="selectedEducation.major"
            value="{{selectedEducation.major}}" />

        <nz-form-label [nzSpan]="24" nzFor="name">Academic Achievements</nz-form-label>
        <input nz-input placeholder="Academic Achievements" [(ngModel)]="selectedEducation.academicAchievements"
            value="{{selectedEducation.academicAchievements}}" />

            <nz-form-label [nzSpan]="24" nzFor="name">Country</nz-form-label>
        <nz-select (ngModelChange)="onCountrySelected($event)" [(ngModel)]=selectedEducation.countryId nzPlaceHolder="find country" [nzShowSearch]="true">
            <nz-option *ngFor="let option of countryData" [nzValue]="option.id"
                [nzLabel]="option.name"></nz-option>
        </nz-select>

        <nz-form-label [nzSpan]="24" nzFor="name">State or Province</nz-form-label>
        <input nz-input placeholder="State" [(ngModel)]="selectedEducation.state"
            value="{{selectedEducation.state}}" />

        <nz-form-label [nzSpan]="24" nzFor="name">Year Degree Conferred</nz-form-label>
        <input nz-input placeholder="Year" [(ngModel)]="selectedEducation.year"
            value="{{selectedEducation.year}}" />



    </ng-template>
    <ng-template #modalEducationFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="saveEducation()">Save</button>
    </ng-template>
</nz-modal>

<nz-row style="float: right;">
    <button  style="width: 120px;" nz-button (click)="saveExperienceAndEducation()" nzType="primary">Save Details</button>&nbsp;
    <button style="width: 120px;margin-left: 20px;"  nz-button (click)="directoryPage()">Cancel</button>

</nz-row>

<div id="dvInstitution" *ngIf="isVisibleInstitution"> 
    <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleInstitution"  [nzMaskClosable]="false" nzTitle="Institution" [nzAutofocus]="null" (nzOnCancel)="CancelOrOKEducationPopup(othersTypeName.Institution)">
        
        <ng-container *nzModalContent>
            <nz-form-label [nzSpan]="24" nzFor="name">Institution</nz-form-label>
            <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Institution" type="text" />
            <br><br>
            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
          </ng-container>
          <ng-container *nzModalFooter>
            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthersEducation(othersTypeName.Institution)">Save</button>
        </ng-container>
     
    </nz-modal>
</div>

<div id="dvCountry" *ngIf="isVisibleCountry"> 
    <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleCountry"  [nzMaskClosable]="false" nzTitle="Country" [nzAutofocus]="null" (nzOnCancel)="CancelOrOKEducationPopup(othersTypeName.Country)">
        
        <ng-container *nzModalContent>
            <nz-form-label [nzSpan]="24" nzFor="name">Country</nz-form-label>
            <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter Country" type="text" />
            <br><br>
            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
          </ng-container>
          <ng-container *nzModalFooter>
            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthersEducation(othersTypeName.Country)">Save</button>
        </ng-container>
     
    </nz-modal>
</div>

<div id="dvDegree" *ngIf="isVisibleDegree"> 
    <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleDegree"  [nzMaskClosable]="false" nzTitle="Degree" [nzAutofocus]="null" (nzOnCancel)="CancelOrOKEducationPopup(othersTypeName.Degree)">
        
        <ng-container *nzModalContent>
            <nz-form-label [nzSpan]="24" nzFor="name">Degree</nz-form-label>
            <input  [(ngModel)]="txtOthersName" nz-input placeholder="Enter Degree" type="text" />
            <br><br>
            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
          </ng-container>
          <ng-container *nzModalFooter>
            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthersEducation(othersTypeName.Degree)">Save</button>
        </ng-container>
     
    </nz-modal>
</div>

<div id="dvUniversity" *ngIf="isVisibleUniversity"> 
    <nz-modal [(nzVisible)]="isVisibleOthers && isVisibleUniversity"  [nzMaskClosable]="false" nzTitle="University" [nzAutofocus]="null" (nzOnCancel)="CancelOrOKEducationPopup(othersTypeName.University)">
        
        <ng-container *nzModalContent>
            <nz-form-label [nzSpan]="24" nzFor="name">University</nz-form-label>
            <input [(ngModel)]="txtOthersName" nz-input placeholder="Enter University" type="text" />
            <br><br>
            <label id="lblsuccessmsg" class="lbl-success-msg">{{othersSuccessMsg}}</label>
          </ng-container>
          <ng-container *nzModalFooter>
            <button style="text-align: center;" nz-button nzType="primary" [disabled]="txtOthersName.length <= 0" (click)="SaveOthersEducation(othersTypeName.University)">Save</button>
        </ng-container>
     
    </nz-modal>
</div>

<nz-modal [(nzVisible)]="isVisibleSuccess"  [nzMaskClosable]="false" nzTitle="Message"  [nzAutofocus]="null" (nzOnCancel)="CancelOKSuccessPopup()">
    <ng-container *nzModalContent>
        <label id="lblsuccessmsg" class="label-success-msg">{{lblSuccessPopupMessage}}</label>
      </ng-container>
      <ng-container *nzModalFooter>
        <button style="text-align: center;" nz-button nzType="primary" (click)="CancelOKSuccessPopup()">OK</button>
    </ng-container>
 
</nz-modal>
